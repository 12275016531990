import { EndSessionRequest } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Routing, UserMgmt as U, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { actions, RegistrationContext } from '../../state/registration'
import { DeleteButton } from '../../ui-user-mgmt'
import { FieldGroup, Form, Submit, SubmitButton, TextField, useFormErrorHandler } from '../../ui-user-mgmt/form'
import { FormValues } from '../../utils/types'
import { Page } from '../layout-user-mgmt'

export interface ProfileProps {
  graphData: U.UserProfile
  setGraphData: Function
  accessToken: string
  orgMembershipStatus: U.OrganizationApplicationStatus | undefined
}

const profileDefaults = (props: ProfileProps): FormValues<U.UserProfile> => ({
  givenName: props.graphData.givenName,
  surname: props.graphData.surname,
  mail: props.graphData.mail,
  mobilePhone: props.graphData.mobilePhone ?? '',
  groups: props.graphData.groups ?? [],
  fintrafficIdRoles: props.graphData.fintrafficIdRoles ?? [],

  organizations:
    props.graphData.organizations ??
    [
      /*{
    name: '',
    businessId: '',
    contactName: '',
    address: '',
    postalCode: '',
    municipality: '',
    phoneNumber: '',
    contactPhoneNumber: '',
    astraGovernmentOrganization: '',
  }*/
    ],
})

const FormAndTitleContainer = styled.div`
  padding-bottom: 48px;
  width: 50%;
`

const FormContainer = styled.div`
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 8px;

  & > button,
  a {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`

const Xicon = styled.span`
  padding 0px 14px;
  font-size: 18px;
  font-weight: 500;
`

/**
 * Renders information about the user obtained from Microsoft Graph
 */
export const ProfileData: React.FunctionComponent<ProfileProps> = props => {
  const { instance } = useMsal()
  const { t } = useTranslation('personal_data')
  const { t: um } = useTranslation('user_management')
  const { t: r } = useTranslation('registration')
  const handleError = useFormErrorHandler<U.UserProfile>()
  const { dispatch } = useContext(RegistrationContext)

  const submit: Submit<U.UserProfile> = async (values, helpers) => {
    // For some reason 'groups' is Map here, convert it to Array
    const { groups: groupMap } = values
    const grpMap = groupMap as unknown as Map<string, U.UserGroup>
    const groups = grpMap ? Array.from(Object.values(grpMap)) : []

    const result = await api.updateProfile({ ...values, ...{ groups } }, { accessToken: props.accessToken })
    if (result.success) {
      props.setGraphData(values)
      helpers.setSubmitting(false)
      dispatch(actions.addSnackbarMessage(t('user_management:saved'), 'SUCCESS'))
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <FormAndTitleContainer>
      <Page.Content title={t('user_management:user_profile')}>
        <FormContainer>
          <Form<U.UserProfile> initialValues={profileDefaults(props)} onSubmit={submit} validate={validators.updateProfile}>
            <FieldGroup title={t('personal_data')}>
              <TextField label={um('given_name')} name={getName(undefined, 'givenName')} disabled={false} />
              <TextField label={t('last_name')} name={getName(undefined, 'surname')} disabled={false} />
            </FieldGroup>
            <FieldGroup title={t('contact_information')}>
              <TextField label={t('email')} name={getName(undefined, 'mail')} disabled={true} />
              <TextField label={um('mobile_phone')} name={getName(undefined, 'mobilePhone')} />
            </FieldGroup>

            <Link to={Routing.patterns.organizations}>
              <h2>{r('user_management:organizations')}</h2>
            </Link>

            <ButtonContainer>
              <SubmitButton labelKey="user_management:update_profile" />
              <DeleteButton
                type="button"
                variant="secondary"
                $fullWidth
                onClick={async () => {
                  if (window.confirm(um('confirm_profile_delete'))) {
                    await api.deleteProfile(undefined, { accessToken: props.accessToken })
                    const endRequest: EndSessionRequest = {
                      postLogoutRedirectUri: '/',
                    }
                    await instance.logout(endRequest)
                  }
                }}
              >
                <Xicon>X</Xicon>
                {t('user_management:delete_profile')}
              </DeleteButton>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </Page.Content>
    </FormAndTitleContainer>
  )
}

const getName = (namespace: string | undefined, name: keyof U.UserProfile): string =>
  namespace ? `${namespace}.${name}` : name
