import React from 'react'
import styled from 'styled-components'
import { ButtonBase } from '../button/button'
import Dropdown from '../dropdown/dropdown'

const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  padding: ${p => p.theme.spacing(1)};
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};
`

const UserMenuItem = styled(ButtonBase)`
  margin: ${p => p.theme.spacing(0.2)};
  color: ${props => props.theme.palette.text.dark};
  font-size: 1rem;
  white-space: nowrap;
  text-align: left;
`

export type UserMenuProps = {
  loginText: string
  logout: () => void
  logoutText: string
}

const StyledDropdown = styled(Dropdown)`
  display: none;
  ${p => p.theme.mediaquery('sm')} {
    display: inline-block;
  }
`

const UserMenu: React.FC<UserMenuProps> = ({ loginText, logout, logoutText }) => (
  <StyledDropdown id="logout" toggleText={loginText} toggleAs={StyledButtonBase} chevron>
    <UserMenuItem onClick={logout}>{logoutText}</UserMenuItem>
  </StyledDropdown>
)

export default UserMenu
