import React from 'react'
import styled from 'styled-components'
import { Col, Row } from '../layout/layout'
import Box from '../box/box'
import { H2 } from '../typography/heading'

type Props = { fieldset?: boolean; title?: string }

export const FieldGroup: React.FC<Props> = ({ children, fieldset, title }) => (
  <GroupContainer as={fieldset ? 'fieldset' : undefined}>
    {title &&
      (fieldset ? (
        <legend>
          <b>{title}</b>
        </legend>
      ) : (
        <b>{title}</b>
      ))}
    {children}
  </GroupContainer>
)

const GroupContainer = styled.div`
  margin: ${p => p.theme.spacing(2)} 0;
  /* Disable fieldset default styling */
  padding: 0;
  border: 0;
`

export const Section: React.FC<Props> = ({ children, title }) => (
  <Box>
    {title && (
      <Row>
        <Col align="center">
          <H2>{title}</H2>
        </Col>
      </Row>
    )}
    <Row align="center">
      <Col align="start" sm={10} md={8} lg={6}>
        {children}
      </Col>
    </Row>
  </Box>
)
