import { InteractionType } from '@azure/msal-browser'
import { TokenClaims } from '@azure/msal-common'
import { useMsalAuthentication } from '@azure/msal-react'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Validation, validators } from 'traficom-registry-shared'
import { Organization } from 'traficom-registry-shared/out/model/UserProfile'
import { api } from '../../services/api'
import { actions, RegistrationContext } from '../../state/registration'
import Loader from '../../ui-common/loader/loader'
import {
  CheckboxField,
  FieldGroup,
  Form,
  Submit,
  SubmitButton,
  TextField,
  useFormErrorHandler,
} from '../../ui-user-mgmt/form'
import { Comp } from '../../utils/component'
import { FormValues } from '../../utils/types'
import { ErrorState } from '../error/error-page'
import { Page } from '../layout-user-mgmt'
import { GroupMembers } from './GroupMembers'
import { getRedirectRequest } from './MsalInstance'
import { WorkQueue, WorkQueueItem } from './WorkQueue'

type MailAddress = { mail: string }

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const FormContainer = styled.div`
  width: 100%;
`

/* eslint-disable */

export const OrganizationAdmin: Comp = () => {
  let { id: businessId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const handleError = useFormErrorHandler<MailAddress>()
  const [roles, setRoles] = useState<string[]>([])
  const [idTokenClaims, setIdTokenClaims] = useState<TokenClaims>()
  const [accessToken, setAccessToken] = useState<string>('')
  const [orgAdminIds, setOrgAdminIds] = useState<string[]>([])
  const [workQueue, setWorkQueue] = useState<WorkQueueItem[]>([])
  const [organization, setOrganization] = useState<Organization>()
  const [hasAppliedForGovOrg, setHasAppliedForGovOrg] = useState<boolean | undefined>(undefined)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const tokenRequest = getRedirectRequest('/#/org-admin')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    const fetchData = async () => {
      if (result) {
        const claims = result.idTokenClaims as TokenClaims
        setIdTokenClaims(claims)
        setRoles(claims.roles ?? [])
        setAccessToken(result.accessToken)

        const adminIds = await api.getOrgAdmins({ businessId: businessId }, { accessToken: result.accessToken })
        if (adminIds.success) {
          setOrgAdminIds(adminIds.data)
        } else {
          console.error(adminIds) // TODO
        }
        const queueResult = await api.getOrgWorkQueue({ businessId: businessId }, { accessToken: result.accessToken })
        if (queueResult.success) {
          setWorkQueue(queueResult.data as WorkQueueItem[])
        } else {
          console.error(queueResult) // TODO
        }
        const orgResult = await api.getOrganizations(undefined, { accessToken: result.accessToken })
        if (orgResult.success) {
          const org = orgResult.data.find(o => o.organization.businessId == businessId)
          setOrganization(org?.organization)
          console.log('org is')
          console.log(org?.organization)
        }
        const govOrgApplicationsStatus = await api.getGovOrgApplicationStatus(
          { businessId: businessId },
          { accessToken: result.accessToken },
        )
        if (govOrgApplicationsStatus.success) {
          setHasAppliedForGovOrg(govOrgApplicationsStatus.data)
        } else {
          console.error(govOrgApplicationsStatus)
        }

        setLoadingComplete(true)
      }
    }

    fetchData().catch(console.error)
  }, [error, result])

  const submit: Submit<MailAddress> = async (values, helpers) => {
    const result = await api.inviteOrgMember({ ...values, businessId: businessId }, { accessToken })
    if (result.success) {
      window.location.reload()
    } else {
      handleError(result.data, helpers)
    }
  }

  if (error) {
    return <ErrorState error="generic" />
  }

  return (
    <PageContainer>
      <FormAndTitleContainer>
        <Page.Content title={t('user_management:organization_admin')}>
          {!loadingComplete && <Loader text={t('common:loading')} />}
          {loadingComplete && (
            <>
              <OrganizationForm org={organization} token={accessToken} initialHasApplied={hasAppliedForGovOrg} />
              <FormContainer>
                <Form<MailAddress> initialValues={{ mail: '' }} onSubmit={submit} validate={userProfileValidator}>
                  <FieldGroup title={t('user_management:invite_users')}>
                    <TextField label={t('personal_data:email')} name="mail" disabled={false} />
                    <SubmitButton labelKey="user_management:invite_user" />
                  </FieldGroup>
                </Form>
              </FormContainer>
              <GroupMembers
                businessId={businessId}
                orgAdminIds={orgAdminIds}
                setOrgAdminIds={setOrgAdminIds}
                token={accessToken}
                adminOid={idTokenClaims?.oid!}
              />
              <WorkQueue items={workQueue} token={accessToken} />
            </>
          )}
        </Page.Content>
      </FormAndTitleContainer>
    </PageContainer>
  )
}

const userProfile: Validation.Builder<MailAddress> = () => ({
  mail: Validation.builders.email,
})

const userProfileValidator = Validation.create(userProfile)

export const AdditionalInfo = styled.p`
  margin-top: 3px;
  margin-bottom: 0px;
  color: #aaa;
  font-style: italic;
`

type OrganizationWithApply = Organization & { applyAstraGovOrg: boolean | undefined }

const StatusText = styled.p`
  font-weight: 600;
`

const OrganizationContainer = styled.div`
  margin-bottom: 50px;
`

const organizationDefaults = (props: OrganizationWithApply): FormValues<OrganizationWithApply> => ({
  name: props.name,
  businessId: props.businessId,
  contactName: props.contactName ?? '',
  address: props.address ?? '',
  postalCode: props.postalCode ?? '',
  municipality: props.municipality ?? '',
  phoneNumber: props.phoneNumber ?? '',
  contactPhoneNumber: props.contactPhoneNumber ?? '',
  astraGovernmentOrganization: props.astraGovernmentOrganization,
  applyAstraGovOrg: props.applyAstraGovOrg,
})

const OrganizationForm: Comp<{
  org: Organization | undefined
  token: string
  initialHasApplied: boolean | undefined
}> = props => {
  const { t } = useTranslation()
  const handleError = useFormErrorHandler<OrganizationWithApply>()
  const { dispatch } = useContext(RegistrationContext)
  const [hasApplied, setHasApplied] = useState<boolean | undefined>(props.initialHasApplied)

  let orgWithApply: OrganizationWithApply
  if (props.org) {
    orgWithApply = { ...props.org, applyAstraGovOrg: false }
  } else {
    orgWithApply = {
      name: '',
      businessId: '',
      contactName: '',
      address: '',
      postalCode: '',
      municipality: '',
      phoneNumber: '',
      contactPhoneNumber: '',
      astraGovernmentOrganization: false,
      applyAstraGovOrg: false,
    }
  }

  const submit: Submit<OrganizationWithApply> = async (values, helpers) => {
    const result = await api.updateOrganization(
      { ...values, applyAstraGovOrg: values.applyAstraGovOrg },
      { accessToken: props.token },
    )

    if (result.success) {
      if (result.data == 'user_management:applied_for_govorg') {
        dispatch(actions.addSnackbarMessage(t('user_management:applied_for_govorg'), 'SUCCESS'))
        setHasApplied(true)
      }
      dispatch(actions.addSnackbarMessage(t('user_management:saved'), 'SUCCESS'))
    } else {
      handleError(result.data, helpers)
    }

    helpers.setSubmitting(false)
  }

  if (props.org === undefined) {
    return <></>
  }

  return (
    <OrganizationContainer>
      <Form<OrganizationWithApply>
        initialValues={organizationDefaults(orgWithApply)}
        onSubmit={submit}
        validate={validators.createOrganization}
      >
        <FieldGroup title={t('registration:organization')}>
          <AdditionalInfo>{t('common:asterisk_mandatory')}</AdditionalInfo>

          <TextField label={t('personal_data:name')} name="name" disabled={false} mandatory={true} />
          <TextField label={t('personal_data:company_id')} name="businessId" disabled={true} mandatory={true} />
          <TextField label={t('personal_data:telephone')} name="phoneNumber" disabled={false} mandatory={true} />
          <TextField label={t('personal_data:address')} name="address" disabled={false} />
          <TextField label={t('personal_data:postal_code')} name="postalCode" disabled={false} />
          <TextField label={t('personal_data:municipality')} name="municipality" disabled={false} />
          <TextField label="Yhteyshenkilön nimi" name="contactName" mandatory={true} />
          <TextField label="Yhteyshenkilön puhelinnumero" name="contactPhoneNumber" disabled={false} mandatory={true} />
          {props.org.astraGovernmentOrganization ? (
            <StatusText>{t('user_management:astra_gov_org')}</StatusText>
          ) : hasApplied ? (
            <StatusText>{t('user_management:astra_gov_org_request_sent')}</StatusText>
          ) : (
            <CheckboxField label={t('user_management:astra_gov_org_create_request')} name="applyAstraGovOrg" />
          )}
          <SubmitButton labelKey="user_management:update_organization" />
        </FieldGroup>
      </Form>
    </OrganizationContainer>
  )
}
