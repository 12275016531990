import React from 'react'
import styled from 'styled-components'

export type BoxProps = {
  className?: string
  variant: 'primary' | 'secondary'
}

const StyledBox = styled.section<BoxProps & { textColor: 'dark' | 'light' }>`
  background-color: ${props =>
    props.variant === 'primary' ? props.theme.palette.primary[500] : props.theme.palette.grey[300]};
  color: ${props => props.theme.palette.text[props.textColor]};
  padding: ${props => props.theme.spacing(2)};
`

const Box: React.FunctionComponent<Partial<BoxProps>> = props => {
  const { className, children, variant = 'secondary' } = props
  const textColor = variant === 'primary' ? 'light' : 'dark'

  return (
    <StyledBox className={className} variant={variant} textColor={textColor}>
      {children}
    </StyledBox>
  )
}

export default Box
