import { DroneEndpoints, DroneRoutes, EndpointName, endpointNames, routes } from './Drone'
import { UserMgmtEndpoints, UserMgmtRoutes, UserMgmtEndpointName, userMgmtEndpointNames, userMgmtRoutes } from './UserMgmt'
import * as Model from './Model'

export { DroneEndpoints as Endpoints, EndpointName as Name, Model, endpointNames, routes }
export { UserMgmtEndpoints, UserMgmtEndpointName, userMgmtEndpointNames, userMgmtRoutes }

export type SuccessCode = 200 | 302
export type ErrorCode = 400 | 401 | 403 | 404 | 500

export type Endpoint<Name extends EndpointName = EndpointName> = DroneEndpoints[Name]
export type Route<Name extends EndpointName = EndpointName> = DroneRoutes[Name]
export type UserMgmtEndpoint<Name extends UserMgmtEndpointName = UserMgmtEndpointName> = UserMgmtEndpoints[Name]
export type UserMgmtRoute<Name extends UserMgmtEndpointName = UserMgmtEndpointName> = UserMgmtRoutes[Name]
