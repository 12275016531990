import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import styled from 'styled-components'

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const SearchInput = ({
  onChange,
  placeholder,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  placeholder: string
}) => {
  return (
    <Container>
      <TextField
        label={placeholder}
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}

export default SearchInput
