import React from 'react'
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { api } from '../../services/api'
// import { LogLevel } from "@azure/msal-browser";

export type AzureData = {
  tenantId: string
  feClientId: string
  beClientId: string
}

type AzureConfig = { azure: AzureData }

export const getAzureConfig = async () => {
  const result = await api.azureConfig()
  if (result.success) {
    return result.data as AzureConfig
  } else {
    throw new Error('Failed to get Azure config')
  }
}

export const azureConfig = await getAzureConfig()

const scope = 'api://' + azureConfig.azure.beClientId + '/use'

export const getRedirectRequest = (redirectPage: string): RedirectRequest => ({
  scopes: [scope],
  redirectStartPage: redirectPage,
})

const tenantId = azureConfig.azure.tenantId ?? ''
const clientId = azureConfig.azure.feClientId ?? ''
const config = {
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/' + tenantId,
    postLogoutRedirectUri: '/',
  },
  // cache: {
  //   cacheLocation: "localStorage",
  //   storeAuthStateInCookie: false
  // },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level: any, message: any, containsPii: any) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     }
  //   }
  // }
}

const msalInstance = new PublicClientApplication(config)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    msalInstance.setActiveAccount(payload.account)
  }
})

const MsalInstance: React.FunctionComponent = ({ children }) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export default MsalInstance
