import React from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { SignInButton } from './SignInButton'
import { InteractionStatus } from '@azure/msal-browser'
import { AuthenticatedMenu } from './authenticated-menu'

const SignInSignOutButton: React.FunctionComponent = () => {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  if (isAuthenticated) {
    return <AuthenticatedMenu />
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    return <SignInButton />
  } else {
    return null
  }
}

export default SignInSignOutButton
