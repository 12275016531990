import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import GlobalStyle from './globalStyle'
import { theme } from './theme'

const ThemeProvider: React.FunctionComponent = ({ children }) => (
  <StyledThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </StyledThemeProvider>
)

export default ThemeProvider
