import React, { ErrorInfo } from 'react'
import { api } from '../../services/api'

type State = { hasError: boolean }

class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch({ message, name, stack }: Error, { componentStack }: ErrorInfo): void {
    api.reportError({ componentStack, message, name, stack })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
