import React from 'react'
import styled from 'styled-components'
import { ButtonBase } from '../button/button'

const StyledMenu = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  min-width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  ${p => p.theme.mediaquery('sm')} {
    display: none;
  }
`

export const HeaderMenuItem = styled.li`
  border-top: ${p => p.theme.borders['s']} solid ${p => p.theme.palette.grey[500]};
  width: calc(100% - ${p => p.theme.spacing(0.4)});
  display: flex;
  font-weight: 600;
  & > * {
    flex: 1 1 auto;
    padding: ${p => p.theme.spacing(1)} 0;
  }
`

const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  padding: 0.625rem;
  white-space: nowrap;
  ${p => p.theme.mediaquery('sm')} {
    display: none;
  }
`

export const HeaderMenu: React.FC<{ id: string }> = ({ id, children }) => (
  <StyledMenu id={id} role="menu">
    {children}
  </StyledMenu>
)

type HeaderMenuToggleProps = {
  menuId: string
  onClick: () => void
  className?: string
  isActive: boolean
}

export const HeaderMenuToggle: React.FC<HeaderMenuToggleProps> = ({ children, menuId, onClick, className, isActive }) => (
  <StyledButtonBase
    aria-controls={menuId}
    aria-expanded={isActive}
    aria-haspopup="true"
    onClick={onClick}
    className={className}
    type="button"
  >
    {children}
  </StyledButtonBase>
)
