import React, { useContext, useEffect } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { PageInfo, RegistrationContext } from '../../state/registration/registration-context'
import { Comp } from '../../utils/component'

const DEFAULT_SITENAME = 'Fintraffic ID'

const PageMeta: Comp<PageInfo> = props => {
  const { i18n } = useTranslation()
  const { title, subTitle } = props

  const { dispatch } = useContext(RegistrationContext)
  useEffect(() => {
    dispatch({ type: 'UPDATE_PAGE_INFO', payload: { title, subTitle } })
  }, [dispatch, title, subTitle])

  return (
    <HelmetProvider>
      <Helmet {...getTitleProps(props)}>
        <html lang={i18n.language} />
      </Helmet>
    </HelmetProvider>
  )
}

type TitleProps = { title?: string; titleTemplate?: string; defaultTitle?: string }

/**
 * Build title related props
 *
 * There are several ways to form the page title:
 * - No title, only site name
 * - A single title
 * - A main title and a sub title defined in the same component
 * - A main title defined in one component and a sub title defined in another component
 * Creative use of defaultTitle and titleTemplate should allow all these.
 */
const getTitleProps = (p: PageInfo): TitleProps => {
  if (p.subTitle && !p.title) {
    // This should be a separately defined sub title, with a matching template already set.
    return { title: p.subTitle }
  }

  const fullTitle = [p.subTitle ?? [], p.title ?? [], DEFAULT_SITENAME].flat().join(' | ')

  return { defaultTitle: fullTitle, titleTemplate: `%s | ${fullTitle}` }
}

export default PageMeta
