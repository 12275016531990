import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Info } from '../../images/info.svg'
import { Comp } from '../../utils/component'
import { Container } from '../layout/layout'
import { H3 } from '../typography/heading'

export type AlertProps = {
  title: string
  text: string
}

const AnnouncementContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing(2)};
  color: ${props => props.theme.palette.primary[700]};
`
const ColorContainer = styled.div`
  background-color: ${props => props.theme.palette.primary[100]};
  & ~ & {
    ${AnnouncementContainer} {
      border-top: ${props => props.theme.borders['s']} solid ${props => props.theme.palette.primary[200]};
    }
  }
`

const IconWrapper = styled.div`
  flex: 0 1 auto;
  padding-right: ${props => props.theme.spacing(2)};
  align-self: flex-start;
  svg {
    width: 30px;
  }
`

const AnnouncementTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: ${props => props.theme.spacing(0.5)} 0;
  }
`

export const Alert: Comp<AlertProps> = props => (
  <ColorContainer>
    <Announcement {...props} />
  </ColorContainer>
)

export const AlertBanner: Comp<AlertProps> = props => (
  <ColorContainer>
    <Container>
      <Announcement {...props} />
    </Container>
  </ColorContainer>
)

const Announcement: Comp<AlertProps> = ({ title, text }) => (
  <AnnouncementContainer>
    <IconWrapper>
      <Info />
    </IconWrapper>
    <AnnouncementTextWrapper>
      <H3>{title}</H3>
      <p>{text}</p>
    </AnnouncementTextWrapper>
  </AnnouncementContainer>
)
