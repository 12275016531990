// From https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes
import countries from './countries-slim-2.json'

export type Country = {
  name: string
  'alpha-2': string
  'country-code': string
}

export const all: Country[] = countries

export const options = all.map(c => ({ value: c['alpha-2'], label: c.name }))
