import React from 'react'
import styled, { css } from 'styled-components'

export type CommonHeadingProps = {
  color?: 'dark' | 'light'
  uppercase?: boolean
  bottomGutter?: boolean
  as?: React.ElementType | keyof JSX.IntrinsicElements
}

const baseStyles = css<CommonHeadingProps>`
  display: block;
  color: ${props => (props.color ? props.theme.palette.text[props.color] : 'inherit')};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  font-weight: bold;
  margin: 0;
  margin-bottom: ${props => props.bottomGutter && '.5em'};
`

const H1 = styled.h1<CommonHeadingProps>`
  ${baseStyles}
  font-size: 42px;
`

const H2 = styled.h2<CommonHeadingProps>`
  ${baseStyles}
  font-size: 24px;
`

const H3 = styled.h3<CommonHeadingProps>`
  ${baseStyles}
  font-size: 18px;
`

const H4 = styled.h4<CommonHeadingProps>`
  ${baseStyles}
  font-size: 16px;
`
const H5 = styled.h5<CommonHeadingProps>`
  ${baseStyles}
  font-size: 13px;
`

export { H1, H2, H3, H4, H5 }
