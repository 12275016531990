import axios, { AxiosError } from 'axios'
import { mapObject } from '../utils'
import { ApiResult, Handlers, Endpoint, Route, routes } from './apiTypes'

const replaceParams = (pattern: string, params: Record<string, string>) =>
  Object.entries(params).reduce((url, [name, value]) => url.replace(`:${name}`, value), pattern)

const getHeaders = ({ accessToken }: Record<string, string | undefined>) => ({
  Authorization: 'Bearer ' + accessToken,
})

function createClientApi() {
  const client = axios.create({ baseURL: '/api' })

  function create<EP extends Endpoint>(route: Route) {
    const { method, pattern } = route

    return async (data: EP['body'], params: EP['path']): Promise<ApiResult<EP>> => {
      const url = replaceParams(pattern, params ?? {})
      const headers = getHeaders(params ?? {})
      try {
        const response = await client.request<EP['result']>({ data, headers, method, url, withCredentials: true })

        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, data: (error as AxiosError).response?.data ?? {} }
      }
    }
  }

  return mapObject(routes, create) as Handlers
}

export const api = createClientApi()
