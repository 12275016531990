import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Routing } from 'traficom-registry-shared'
import { Button, Markdown } from '../../ui-common'
import { Section } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { Page } from '../layout'

export const CookiePolicyPage: Comp = () => {
  const { t } = useTranslation()

  return (
    <Page.Content title={t('common:cookie-policy')}>
      <Section>
        <Markdown>{t('common:cookie-policy-description')}</Markdown>
        <Button as={Link} to={Routing.patterns.frontPage} variant="secondary">
          {t('common:go_back')}
        </Button>
      </Section>
    </Page.Content>
  )
}
