import {
  CreateOrganizationStatus,
  JoinOrganizationResponse,
  OrganizationApplicationStatus,
  OrgMembership,
  OrgMembersResponse,
  UserGroup,
  UserProfile,
} from '../model/UserProfile'
import { HealthResponse } from './Model'

export type UserMgmtEndpoints = typeof userMgmtRoutes & {
  getProfile: { path: undefined; body: undefined; result: UserProfile }
  getUsersByEmail: { path: undefined; body: object; result: object[] }
  updateProfile: { path: undefined; body: UserProfile; result: undefined }
  deleteProfile: { path: undefined; body: undefined; result: undefined }
  deleteUserById: { path: undefined; body: object; result: undefined }
  inviteOrgMember: { path: undefined; body: object; result: undefined }
  addUserToGroup: { path: undefined; body: object; result: undefined }
  removeUserFromGroup: { path: undefined; body: object; result: undefined }
  getOrgMembers: { path: undefined; body: object; result: OrgMembersResponse[] }
  getOrgAdmins: { path: undefined; body: object; result: string[] }
  deleteOrgMember: { path: undefined; body: object; result: undefined }
  addOrgAdmin: { path: undefined; body: object; result: undefined }
  removeOrgAdmin: { path: undefined; body: object; result: undefined }
  getOrgWorkQueue: { path: undefined; body: object; result: object[] }
  getAnsWorkQueue: { path: undefined; body: undefined; result: object[] }
  getGovOrgApplicationStatus: { path: undefined; body: object; result: boolean }
  getOrganizationApplicationStatus: { path: undefined; body: undefined; result: OrganizationApplicationStatus }
  deleteWorkQueueItem: { path: undefined; body: object; result: undefined }
  getOrgGroupPermissions: { path: undefined; body: undefined; result: object[] }
  saveOrgGroupPermission: { path: undefined; body: object; result: object }
  deleteOrgGroupPermission: { path: undefined; body: object; result: undefined }
  acceptJoinRequest: { path: undefined; body: object; result: undefined }
  rejectJoinRequest: { path: undefined; body: object; result: undefined }
  createOrganization: { path: undefined; body: object; result: CreateOrganizationStatus }
  createOrganizationOnBehalf: { path: undefined; body: object; result: undefined }
  getOrganizations: { path: undefined; body: undefined; result: OrgMembership[] }
  getAllOrganizations: { path: undefined; body: undefined; result: UserGroup[] }
  getOrganizationsByNameOrBusinessId: { path: undefined; body: object; result: object[] }
  updateOrganization: { path: undefined; body: object; result: undefined | string }
  updateOrganizationAsGovOrg: { path: undefined; body: object; result: undefined }
  joinOrganization: { path: undefined; body: object; result: JoinOrganizationResponse }
  azureConfig: { path: undefined; body: undefined; result: object }
  health: { path: undefined; body: undefined; result: HealthResponse }
}

export const userMgmtRoutes = {
  getProfile: { method: 'get', pattern: '/um/get-profile', security: 'bearer' },
  getUsersByEmail: { method: 'post', pattern: '/um/get-user-by-email', security: 'bearer' },
  updateProfile: { method: 'post', pattern: '/um/update-profile', security: 'bearer' },
  deleteProfile: { method: 'delete', pattern: '/um/delete-profile', security: 'bearer' },
  deleteUserById: { method: 'post', pattern: '/um/delete-user', security: 'bearer' },
  inviteOrgMember: { method: 'post', pattern: '/um/invite-user', security: 'bearer' },
  addUserToGroup: { method: 'post', pattern: '/um/add-user-to-group', security: 'bearer' },
  removeUserFromGroup: { method: 'delete', pattern: '/um/remove-user-from-group', security: 'bearer' },
  getOrgMembers: { method: 'post', pattern: '/um/get-members', security: 'bearer' },
  getOrgAdmins: { method: 'post', pattern: '/um/get-admins', security: 'bearer' },
  deleteOrgMember: { method: 'post', pattern: '/um/delete-member', security: 'bearer' },
  addOrgAdmin: { method: 'post', pattern: '/um/add-org-admin', security: 'bearer' },
  removeOrgAdmin: { method: 'post', pattern: '/um/remove-org-admin', security: 'bearer' },
  getOrgWorkQueue: { method: 'post', pattern: '/um/get-org-work-queue', security: 'bearer' },
  getAnsWorkQueue: { method: 'get', pattern: '/um/get-ans-work-queue', security: 'bearer' },
  getOrgGroupPermissions: { method: 'get', pattern: '/um/get-org-group-permissions', security: 'bearer' },
  saveOrgGroupPermission: { method: 'put', pattern: '/um/save-org-group-permission', security: 'bearer' },
  deleteOrgGroupPermission: { method: 'delete', pattern: '/um/delete-org-group-permission', security: 'bearer' },
  getGovOrgApplicationStatus: { method: 'post', pattern: '/um/get-gov-org-status', security: 'bearer' },
  getOrganizationApplicationStatus: { method: 'get', pattern: '/um/get-org-apply-status', security: 'bearer' },
  acceptJoinRequest: { method: 'post', pattern: '/um/accept-join-req', security: 'bearer' },
  deleteWorkQueueItem: { method: 'post', pattern: '/um/delete-wq-item', security: 'bearer' },
  rejectJoinRequest: { method: 'post', pattern: '/um/reject-join-req', security: 'bearer' },
  createOrganization: { method: 'post', pattern: '/um/create-organization', security: 'bearer' },
  createOrganizationOnBehalf: { method: 'post', pattern: '/um/create-org-onbehalf', security: 'bearer' },
  getOrganizations: { method: 'get', pattern: '/um/get-organizations', security: 'bearer' },
  getAllOrganizations: { method: 'get', pattern: '/um/get-all-organizations', security: 'bearer' },
  getOrganizationsByNameOrBusinessId: {
    method: 'post',
    pattern: '/um/get-organizations-by-name-business-id',
    security: 'bearer',
  },
  updateOrganization: { method: 'post', pattern: '/um/update-organization', security: 'bearer' },
  updateOrganizationAsGovOrg: { method: 'post', pattern: '/um/update-organization-as-gov-org', security: 'bearer' },
  joinOrganization: { method: 'post', pattern: '/um/join-organization', security: 'bearer' },
  azureConfig: { method: 'get', pattern: '/um/config', security: 'none' },
  health: { method: 'get', pattern: '/um/health', security: 'none' },
} as const

export type UserMgmtEndpointName = keyof typeof userMgmtRoutes

export const userMgmtEndpointNames = Object.keys(userMgmtRoutes) as UserMgmtEndpointName[]

export type UserMgmtRoutes = typeof userMgmtRoutes
