import styled from 'styled-components'
import { useMsal } from '@azure/msal-react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { Comp } from '../../utils/component'
import { ButtonBase } from '../../ui-common/button/button'
import { useTranslation } from 'react-i18next'
import Dropdown from '../../ui-common/dropdown/dropdown'

const UserMenuItem = styled(ButtonBase)`
  margin: ${p => p.theme.spacing(0.2)} 0;
  color: ${props => props.theme.palette.text.dark};
  font-size: 1rem;
  white-space: nowrap;
  text-align: left;
  padding-left: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  padding: ${p => p.theme.spacing(1)};
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};
`

const LanguageToggle: React.FC = ({ children, ...props }) => (
  <StyledButtonBase {...props}>
    <AccountCircle />
    {children}
  </StyledButtonBase>
)

export const AuthenticatedMenu: Comp = () => {
  const { instance, accounts } = useMsal()
  const { t } = useTranslation('user_management')

  const logout = () => {
    instance.logoutRedirect()
  }

  const handleClick = () => {
    window.location.href = '/#/user-profile'
  }

  return (
    <Dropdown
      id="authenticated"
      toggleText={accounts[0] ? (accounts[0].name ? accounts[0].name : '') : ''}
      toggleAs={LanguageToggle}
      chevron
    >
      <UserMenuItem onClick={handleClick}>{t('edit_profile')}</UserMenuItem>
      <UserMenuItem onClick={logout}>{t('logout')}</UserMenuItem>
    </Dropdown>
  )
}
