import axios from 'axios'
import { useContext, useEffect } from 'react'
import { Validation } from 'traficom-registry-shared'
import { Announcement, AnnouncementContent, Duration, RegistrationContext } from '../state/registration'

const { datetime, nonEmpty } = Validation.builders

const content: Validation.Builder<AnnouncementContent> = () => ({ title: nonEmpty, text: nonEmpty })

const duration: Validation.Builder<Duration> = () => ({ start: datetime, end: datetime })

const builder: Validation.Builder<Announcement> = () => ({
  active: duration,
  unavailable: Validation.optionalObject(duration),
  content: () => ({ en: content, fi: content, sv: content }),
})

const validator = Validation.create(builder)
const isValid = (a: Announcement): boolean => Object.keys(validator(a) ?? {}).length === 0

export const useAnnouncements = (): Announcement[] => {
  const { dispatch, state } = useContext(RegistrationContext)

  const fetchAnnouncements = async () => {
    let cancelled = false

    if (state.announcements.status === 'NOT_LOADED') {
      dispatch({ type: 'SET_ANNOUNCEMENTS', payload: { status: 'PENDING', values: [] } })

      const values = await axios
        .get<Announcement[]>('/res/announcements.json')
        .then(r => r.data.filter(isValid))
        .catch(() => [])

      if (cancelled) {
        return
      }
      dispatch({ type: 'SET_ANNOUNCEMENTS', payload: { status: 'LOADED', values } })
    }

    return () => {
      cancelled = true
    }
  }

  useEffect(() => {
    fetchAnnouncements()
  })

  return state.announcements.values
}

export const isDuring = (date: Date, { start, end }: Duration): boolean => new Date(start) < date && new Date(end) > date
