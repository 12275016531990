import { FormikErrors, FormikHelpers } from 'formik'
import { FormValues } from '../../utils/types'

export type Submit<Values> = (values: Values, formikHelpers: FormikHelpers<FormValues<Values>>) => void | Promise<unknown>

export enum FormSubmitStatus {
  NOT_SENT = 'NOT_SENT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export function getFirstErrorPath<T>(errors: FormikErrors<T>, path = ''): string | null {
  for (const key in errors) {
    if (errors[key] && typeof errors[key] === 'object') {
      return getFirstErrorPath(errors[key] as FormikErrors<T>, key + '.')
    } else {
      return path + key
    }
  }
  return null
}
