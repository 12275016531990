import 'flexboxgrid-helpers/dist/flexboxgrid-helpers.min.css'
import 'flexboxgrid/dist/flexboxgrid.css'
import { HashRouter as Router } from 'react-router-dom'
import styled from 'styled-components'
import ServiceBreak from './components/site-availability/service-break'
import ErrorBoundary from './components/error-boundary/error-boundary'
import Footer from './ui-common/footer/footer'
import Header from './ui-common/header/header'
import { Routes } from './router/routes'
import { RegistrationContextWrapper } from './state/registration/registration-context'
import { mixins } from './styling/mixins'
import ThemeProvider from './styling/themeProvider'
import Snackbar from './ui-common/snackbar/snackbar'
import ScrollToTop from './router/ScrollToTop'

const StyledMain = styled.main`
  ${mixins.layout.flexStretch}
`

function App(): JSX.Element {
  return (
    <RegistrationContextWrapper>
      <ThemeProvider>
        <Router>
          <ScrollToTop />
          <Header />
          <StyledMain>
            <ServiceBreak>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </ServiceBreak>
          </StyledMain>
          <Footer />
          <Snackbar />
        </Router>
      </ThemeProvider>
    </RegistrationContextWrapper>
  )
}

export default App
