import React from 'react'
import styled, { css } from 'styled-components'
import Box from '../box/box'
import { ReactComponent as TickMark } from '../../images/tick-mark.svg'
import { ReactComponent as ExclamationMark } from '../../images/exclamation-mark.svg'

type State = 'success' | 'error'

export type EmptyStateProps = {
  title?: string
  subtitle?: string
  state: State
}

const StyledEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.2rem 1.6rem;
  text-align: center;
  max-width: 520px;
  margin: 0 auto;
`

const StyledIconContainer = styled.div`
  margin-bottom: 0.8rem;
`
const StyledActionContainer = styled.div`
  margin-top: 0.8rem;
`

// change this after DRONE-138
const CustomH3Title = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin: 0.4rem auto;
`

const iconSize = css`
  height: 8rem;
  width: 8rem;
`

const StyledSuccess = styled(TickMark)`
  ${iconSize}
  color: ${props => props.theme.palette.secondary};
`
const StyledError = styled(ExclamationMark)`
  ${iconSize}
  color: ${props => props.theme.palette.primary[500]};
`

const EmptyState: React.FunctionComponent<EmptyStateProps> = ({ title, subtitle, state, children }) => {
  const Icon = state === 'error' ? StyledError : StyledSuccess
  return (
    <Box>
      <StyledEmptyStateContainer>
        <StyledIconContainer>
          <Icon />
        </StyledIconContainer>
        {title && <CustomH3Title>{title}</CustomH3Title>}
        {subtitle && <p>{subtitle}</p>}
        <StyledActionContainer>{children}</StyledActionContainer>
      </StyledEmptyStateContainer>
    </Box>
  )
}

export default EmptyState
