import React from 'react'
import styled from 'styled-components'
import { useMsal } from '@azure/msal-react'
import { azureConfig } from './MsalInstance'
import { Comp } from '../../utils/component'
import { ButtonBase } from '../../ui-common/button/button'
import { useTranslation } from 'react-i18next'

const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  padding: ${p => p.theme.spacing(1)};
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};
`

export const SignInButton: Comp = () => {
  const { instance } = useMsal()
  const { t } = useTranslation('user_management')

  const scope = 'api://' + azureConfig.azure.beClientId + '/use'
  const request = {
    scopes: [scope],
    redirectStartPage: '/#/user-profile',
  }

  const handleLogin = () => {
    instance.loginRedirect(request)
    //instance.acquireTokenRedirect(loginRequest)
  }

  return (
    <nav>
      <StyledButtonBase onClick={() => handleLogin()}>{t('login')}</StyledButtonBase>
    </nav>
  )
}
