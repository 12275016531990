import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  /* latin-ext */
  @font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgDckd1sdfg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgDcqd1s.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNIfJ7Cww.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/publicsans/v11/ijwRs572Xtc6ZYQws9YVwnNGfJ4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html, body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Public Sans', -apple-system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${p => p.theme.palette.grey[100]};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export default GlobalStyle
