import { InteractionType } from '@azure/msal-browser'
import { useMsalAuthentication } from '@azure/msal-react'
import { User } from '@microsoft/microsoft-graph-types'
import { ArrowDropDown } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, Pagination } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OrgMembersResponse } from 'traficom-registry-shared/out/model/UserProfile'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import usePagination from '../../hooks/use-pagination'
import { api } from '../../services/api'
import SearchInput from '../../ui-common/search-input/SearchInput'
import { FieldGroup } from '../../ui-user-mgmt/form'
import LoadingSpinner from '../../ui-user-mgmt/loading/loading-spinner'
import { Comp } from '../../utils/component'
import { getRedirectRequest } from './MsalInstance'

export const CardContent = styled.div<any>`
  padding: 0px 30px 40px 30px;
  width: 100%;
`

const SwitchArea = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
`

const NameInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const UpperSection = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 28px;
`

export type DotMenuProps = {
  openMenu: boolean
}

const CheckBoxWrapper = styled.div<any>`
  position: relative;
`

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 2px;
  background: #bebebe;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    margin: 4px;
    background: #ffffff;
    transition: 0.2s;
  }
`

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 2px;
  width: 42px;
  height: 26px;

  &:checked + ${CheckBoxLabel} {
    background: #1777f8;

    &::after {
      content: '';
      display: block;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      margin-left: 20px;
      transition: 0.2s;
    }
  }

  &:disabled + ${CheckBoxLabel} {
    background: #9696aa;
    cursor: default;

    &::after {
      content: '';
      display: block;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      transition: 0.2s;
      background: #cdcdd7;
    }
  }
`

const AdminRoleLabel = styled.label`
  padding-left: 10px;
  padding-right: 18px;
  vertical-align: top;
`

export const AdditionalInfo = styled.p`
  margin-top: 3px;
  margin-bottom: 0px;
  color: #aaa;
  font-style: italic;
`

/* eslint-disable */

export const GroupMembers: Comp<{
  businessId: string
  orgAdminIds: string[]
  setOrgAdminIds: Function
  token: string
  adminOid: string
}> = props => {
  const [grpMembers, setGrpMembers] = useStateWithCallbackLazy<(OrgMembersResponse & { loading?: boolean })[]>([])
  const { t } = useTranslation()

  const tokenRequest = getRedirectRequest('/#/org-admin')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)
  const [searchName, setSearchName] = useState('')

  const fetchData = async () => {
    if (props.token) {
      const members = await api.getOrgMembers({ businessId: props.businessId }, { accessToken: props.token })
      if (members.success) {
        const sortkey = (user: User) =>
          user.surname && user.givenName ? user.surname + ' ' + user.givenName : (user.displayName as string)
        const sorted = members.data
        sorted.sort((a, b) => sortkey(a.user).localeCompare(sortkey(b.user)))

        setGrpMembers(sorted, () => {})
      } else {
        console.error(members) // TODO
      }
    }
  }

  useEffect(() => {
    fetchData()

    if (result !== null) {
      fetchData().catch(console.error)
    }
  }, [error, result])

  const indicateUserProgress = async (user: OrgMembersResponse, performOp: Promise<any>) => {
    setGrpMembers(
      grpMembers.map(m => ({ ...m, loading: m.user.id == user.user.id ? true : false })),
      () => {
        performOp.then(() => {
          setGrpMembers(
            grpMembers.map(m => ({ ...m, loading: false })),
            () => {},
          )
        })
      },
    )
  }

  const toggleOrgAdminRole = async (user: OrgMembersResponse) => {
    const usr = grpMembers.filter(u => u.user.id == user.user.id)[0]

    const doOp = async () => {
      if (memberIsAdmin(usr.user.id)) {
        await api.removeOrgAdmin({ userId: user.user.id, businessId: props.businessId }, { accessToken: props.token })
        props.setOrgAdminIds(props.orgAdminIds.filter(i => i !== usr.user.id))
      } else {
        await api.addOrgAdmin({ userId: user.user.id, businessId: props.businessId }, { accessToken: props.token })
        props.setOrgAdminIds([...props.orgAdminIds, usr.user.id])
      }
    }

    await indicateUserProgress(user, doOp())
  }

  const toggleGroupMembership = async (user: OrgMembersResponse & { loading?: boolean }, groupId: string) => {
    const usr = grpMembers.filter(u => u.user.id == user.user.id)[0]

    setGrpMembers(
      grpMembers.map(m => ({ ...m, loading: m.user.id == user.user.id ? true : false })),
      () => {
        const group = usr.groups.filter(g => g.groupId == groupId)[0]

        const prom = group.member
          ? api.removeUserFromGroup(
              { userId: user.user.id, groupId: groupId, businessId: props.businessId },
              { accessToken: props.token },
            )
          : api.addUserToGroup(
              { userId: user.user.id, groupId: groupId, businessId: props.businessId },
              { accessToken: props.token },
            )

        prom
          .then(() => {
            const updatedMembers = grpMembers.map(m =>
              m.user.id == user.user.id
                ? {
                    user: m.user,
                    groups: usr.groups.map(g =>
                      g.groupId == groupId
                        ? {
                            groupId: g.groupId,
                            groupDisplayName: g.groupDisplayName,
                            member: !g.member,
                            enabled: g.enabled,
                          }
                        : g,
                    ),
                  }
                : m,
            ) as OrgMembersResponse[]

            setGrpMembers(updatedMembers, () => {})
          })
          .catch(error => {
            setGrpMembers(
              grpMembers.map(m => ({ ...m, loading: false })),
              () => {},
            )
          })
      },
    )
  }

  const memberIsAdmin = (memberId: string | undefined) => {
    return memberId ? props.orgAdminIds.includes(memberId) : false
  }

  const filteredMembers = () => {
    return grpMembers.filter(m => m.user.displayName?.toLowerCase().includes(searchName.toLowerCase())).sort()
  }

  const CheckboxContainer = useCallback(
    ({ m }: { m: OrgMembersResponse }) => {
      return (
        <SwitchArea>
          <CheckBoxWrapper>
            <CheckBox
              id={m.user.id}
              type="checkbox"
              checked={memberIsAdmin(m.user.id)}
              disabled={props.adminOid === m.user.id}
              onChange={async () => await toggleOrgAdminRole(m)}
            />
            <CheckBoxLabel htmlFor={m.user.id} />
            <AdminRoleLabel htmlFor={m.user.id}>{t('user_management:admin_role')}</AdminRoleLabel>
          </CheckBoxWrapper>

          {m.groups.map((g: any) => (
            <CheckBoxWrapper key={m.user.id + g.groupId}>
              <CheckBox
                id={m.user.id + g.groupId}
                type="checkbox"
                checked={g.member}
                disabled={!g.enabled}
                onChange={async () => await toggleGroupMembership(m, g.groupId)}
              />
              <CheckBoxLabel htmlFor={m.user.id + g.groupId} />
              <AdminRoleLabel htmlFor={m.user.id + g.groupId}>{g.groupDisplayName}</AdminRoleLabel>
            </CheckBoxWrapper>
          ))}
        </SwitchArea>
      )
    },
    [toggleGroupMembership, toggleOrgAdminRole],
  )

  const [page, setPage] = useState(1)
  const PER_PAGE = 50
  const paginatedData = usePagination(grpMembers, PER_PAGE)

  const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
    setPage(page)
    paginatedData.jump(page)
  }

  return (
    <FieldGroup title={t('user_management:organization_members')}>
      <SearchInput
        placeholder={t('user_management:search_member')}
        key="input"
        onChange={e => setSearchName(e.target.value)}
      />
      {grpMembers && paginatedData.currentData().length > 0 && (
        <>
          {(searchName.length > 0
            ? filteredMembers()
            : paginatedData
                .currentData()
                .filter((f: any) => f.user.displayName.toLowerCase().includes(searchName.toLowerCase()))
          ).map((m: any) => (
            <Accordion
              key={m.user.id}
              sx={{
                marginBottom: '16px',
                background: '#fff',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16), 0px 6px 6px rgba(0, 0, 0, 0.23)',
              }}
            >
              <AccordionSummary expandIcon={<ArrowDropDown />}>{m.user.displayName}</AccordionSummary>
              <AccordionDetails>
                <CardContent>
                  <UpperSection>
                    <NameInfo>
                      <p>{m.user.mail}</p>
                    </NameInfo>
                  </UpperSection>

                  <LoadingSpinner isLoading={m.loading}>
                    <CheckboxContainer m={m as OrgMembersResponse} />

                    {props.adminOid === m.user.id && (
                      <AdditionalInfo>{t('user_management:admin_role_change_denied')}</AdditionalInfo>
                    )}
                    {m.groups
                      .filter(g => !g.enabled)
                      .map(g => (
                        <AdditionalInfo key={'ai' + m.user.id + g.groupId}>
                          {t('user_management:org_group_admin_expired', { groupName: g.groupDisplayName })}
                        </AdditionalInfo>
                      ))}

                    <Button
                      sx={{
                        position: 'relative',
                        top: '30px',
                      }}
                      variant="outlined"
                      onClick={async () => {
                        if (window.confirm(t('user_management:confirm_remove_org_member'))) {
                          const result = await api.deleteOrgMember(
                            { userId: m.user.id, businessId: props.businessId },
                            { accessToken: props.token },
                          )
                          if (result.success) {
                            window.location.reload()
                          } else {
                            console.error() // TODO
                          }
                        }
                      }}
                    >
                      {t('user_management:remove_member')}
                    </Button>
                  </LoadingSpinner>
                </CardContent>
              </AccordionDetails>
            </Accordion>
          ))}

          <Pagination
            disabled={searchName.length > 0}
            count={Math.ceil(grpMembers.length / PER_PAGE)}
            page={page}
            onChange={handlePageChange}
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
          />
        </>
      )}
    </FieldGroup>
  )
}
