import { Form as FormikForm, Formik, FormikHelpers } from 'formik'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Validation } from 'traficom-registry-shared'
import { FailureData } from '../../services/apiTypes'
import { actions, RegistrationContext } from '../../state/registration'
import { FormValues } from '../../utils/types'
import { PromptIfDirty, ScrollToFirstInvalidField } from './field'
import { Submit } from './util'

type Props<Data extends object> = {
  children: React.ReactNode
  // The form can contain values we don't want to submit -- empty values in select fields for example.
  // The FormValues type and the related type cast fudge around that.
  initialValues: FormValues<Data>
  onSubmit: Submit<Data>
  validate: Validation.Validator<Data>
}

type ErrorHandler<Data extends object> = (
  result: FailureData<Data>,
  { setErrors, setSubmitting }: FormikHelpers<FormValues<Data>>,
) => void

export function useFormErrorHandler<Data extends object>(): ErrorHandler<Data> {
  const { t } = useTranslation()
  const { dispatch } = useContext(RegistrationContext)

  return (result, { setErrors, setSubmitting }) => {
    setErrors(result.errors ?? {})
    setSubmitting(false)
    dispatch(actions.addSnackbarMessage(t(result.message ?? 'registration:submit_failed'), 'ERROR'))
  }
}

export function Form<Data extends object>(props: Props<Data>): JSX.Element {
  const { children, initialValues, onSubmit, validate } = props

  /*
    Formik sets submitting state to false (setSubmitting(false)) when asynchronous submit is resolved.
    We want to prevent this by wrapping handleSubmit promise to void function.
  */
  const syncSubmit = (values: FormValues<Data>, formikHelpers: FormikHelpers<FormValues<Data>>) => {
    onSubmit(Validation.clearEmptyValues(values) as Data, formikHelpers)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={syncSubmit}
      validate={values => validate(Validation.clearEmptyValues(values) as Validation.Value<Data>)}
    >
      <FormikForm>
        <FormContent>
          <ScrollToFirstInvalidField />
          <PromptIfDirty />
          {children}
        </FormContent>
      </FormikForm>
    </Formik>
  )
}

const FormContent = styled.div`
  > * {
    margin-bottom: ${p => p.theme.spacing(1)};
  }
`
