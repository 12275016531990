import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { mixins } from '../../styling/mixins'
import Button from '../button/button'
import { H2 } from '../typography/heading'

const Container = styled.div`
  height: 100%;
  padding: ${p => p.theme.spacing(1)} 0;
`
const cardStyleMixin = css`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
  height: 100%;
  text-decoration: none;
`

const CardLink = styled(Link)`
  ${cardStyleMixin}
`

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-top: 56.25%; //16/9
  }
`

const Image = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(2)};
  p {
    color: ${p => p.theme.palette.text.dark};
  }
`

const DummyButton = styled(Button)<{ disabled: boolean }>`
  margin-top: auto;
  ${p =>
    p.disabled &&
    css`
      &,
      &:hover {
        ${mixins.button.disabledButtonMixin}
      }
    `};
`

type LinkItem = {
  label: string
  to?: string
  variant?: 'primary' | 'secondary' | 'negative'
}

export type CardProps = {
  imageSrc: string
  altText?: string
  title: string
  content: string
  link: LinkItem
}

const Card: React.FunctionComponent<CardProps> = ({ imageSrc, altText, title, content, link }) => {
  return (
    <Container>
      <CardLink {...(link.to ? { to: link.to } : { as: 'div' })}>
        <ImageWrapper>
          <Image src={imageSrc} alt={altText || ''} />
        </ImageWrapper>
        <ContentWrapper>
          <H2 color="dark">{title}</H2>
          {content?.length > 0 && <p>{content}</p>}
          <DummyButton
            variant={link.variant || 'negative'}
            aria-disabled={!link.to}
            disabled={!link.to}
            as="span"
            $fullWidth
          >
            {link.label}
          </DummyButton>
        </ContentWrapper>
      </CardLink>
    </Container>
  )
}
export default Card
