import { UserLanguage } from './Locale'
import * as Registration from './Registration'

export type PersonBase = {
  firstNames: string
  lastName: string
}
type PersonWithSsn = PersonBase & {
  ssn: string
}
type PersonWithoutSsn = PersonBase & {
  /** Date in ISO 8601 format, for example 2020-11-20 */
  birthDate: string
  /** Country code in ISO 3166-1 alpha-2 format, for example: 'FI', 'SE', 'DE' */
  birthCountry: string
  /** Country code in ISO 3166-1 alpha-2 format, for example: 'FI', 'SE', 'DE' */
  nationality: string
  /** Traficom gender codeset value, likely '1' | '2' */
  gender: string
}
export type PersonCommon = PersonBase & ContactInfo & { ssn?: string }

export const consentOptions = [
  'developmentAndInnovationActivities',
  'directMarketing',
  'individualRelease',
  'openInterfaces',
  'transportRelatedPurposes',
] as const
export type ConsentOption = (typeof consentOptions)[number]
/** Traficom consent values: If set to true, using the information for the specified purpose is not allowed. */
export type Consents = Record<ConsentOption, boolean>
export type SomeConsents = { consents: Partial<Consents> }

export type PersonWithConsents = PersonWithoutSsn & ContactInfo & { consents: Consents }

export const organizationConsentOptions: Readonly<ConsentOption[]> = ['developmentAndInnovationActivities']
type OrganizationConsents = Pick<Consents, 'developmentAndInnovationActivities'>

export type ContactInfo = {
  email: string
  phone: string
}
type WithLanguage = {
  /** Traficom language codeset value, likely '1' | '2' | '3' */
  language: string
}

/** Simplified Address based on Traficom.Address */
export type Address = {
  street: string
  municipality: string
  postalCode: string
  /** Country code in ISO 3166-1 alpha-2 format, for example: 'FI', 'SE', 'DE' */
  country: string
}

type OperatorBase = {
  insuranceInfo?: string
  confirmationOfCompetence: boolean
  registrationPeriod: Registration.Period
}

export type PersonEmailAuth = { authType: 'EMAIL' } & PersonWithConsents
export type PersonStrongAuth = { authType: 'STRONG' } & PersonWithSsn & ContactInfo
export type ContactPerson = PersonEmailAuth | PersonStrongAuth

export type OperatorPersonEmailAuth = PersonEmailAuth & OperatorBase & { address: Address } & WithLanguage
export type OperatorPersonStrongAuth = PersonStrongAuth & OperatorBase & { address?: Address; registerAsPilot: boolean }
export type OperatorPerson = OperatorPersonEmailAuth | OperatorPersonStrongAuth

type OrganizationInfo = ContactInfo & OperatorBase & { name: string; signingAuthority: boolean }
export type OrganizationWithFinnishId = OrganizationInfo & { hasFinnishId: true; finnishOrganizationId: string }
export type OrganizationWithoutFinnishId = OrganizationInfo &
  WithLanguage &
  OrganizationConsents & { hasFinnishId: false; foreignOrganizationId?: string; address: Address }
type Organization = OrganizationWithFinnishId | OrganizationWithoutFinnishId

export type OperatorOrganization = Organization & { contact: ContactPerson }

export type Operator =
  | ({ operatorKind: 'person'; userLanguage: UserLanguage } & OperatorPerson)
  | ({ operatorKind: 'organization'; userLanguage: UserLanguage } & OperatorOrganization)

export type PilotDetails = PersonWithSsn & ContactInfo & { address?: Address }

export type OperatorPilotBase = {
  pilot: PilotDetails
  parentalConfirmation: boolean
  operatorIdentifier: string
}
export type OperatorPilotPerson = OperatorPilotBase & { operatorKind: 'person'; credentials: PersonWithSsn }
export type OperatorPilotOrganization = OperatorPilotBase & { operatorKind: 'organization'; changeIdentifier: string }

export type OperatorPilot = OperatorPilotPerson | OperatorPilotOrganization

/**
 * Independent pilot registration model
 *
 * Strong identification fields are not included, data is combined in the backend.
 */
export type IndependentPilot = IndependentPilotBase & { userLanguage: UserLanguage }
export type IndependentPilotBase = (AuthStrong & ContactInfo & { address?: Address }) | (AuthEmail & PersonExpanded)

export type PersonExpanded = PersonWithConsents & WithLanguage & { address: Address }

/**
 * Operator update model
 *
 * Does NOT include fields from an identified person! (The registration model does at the moment.)
 *
 * The update model varies based on three discriminator fields:
 * - `operatorKind`: Whether the operator is a person or an organization
 * - `authType`: How the operator has authenticated
 * - `contactAction`: Whether the contact person information is being updated, or replaced (only organizations)
 */
export type UpdateOperator =
  | (UpdateOperatorBase & AuthStrong & KindOrganization & ContactReplace & { contact: ContactInfo })
  | (UpdateOperatorBase & AuthStrong & KindOrganization & ContactUpdate & { contact: Partial<ContactInfo> })
  | (UpdateOperatorBase & AuthStrong & KindPerson)
  | (UpdateOperatorBase & AuthNone & KindOrganization & ContactReplace & { contact: PersonWithConsents })
  | (UpdateOperatorBase & AuthNone & KindOrganization & ContactUpdate & { contact: Partial<ContactInfo> & SomeConsents })
  | (UpdateOperatorBase & AuthNone & KindPerson & SomeConsents)

export type UpdateOperatorPerson = UpdateOperator & KindPerson
export type UpdateOperatorOrganization = UpdateOperator & KindOrganization

// Discriminator helper types
type AuthStrong = { authType: 'STRONG' }
type AuthEmail = { authType: 'EMAIL' }
type AuthNone = { authType: 'NONE'; changeIdentifier: string }
type ContactReplace = { contactAction: 'replace' }
type ContactUpdate = { contactAction: 'update' }
// Organizations always use changeIdentifier, regardless of authentication:
type KindOrganization = { operatorKind: 'organization'; changeIdentifier: string }
type KindPerson = { operatorKind: 'person' }

export type UpdateOperatorBase = UpdateContactInfo & { insuranceInfo?: string; operatorIdentifier: string }
// Strongly identified person operator does not have the language field, but easier to include it here:
export type UpdateContactInfo = Partial<ContactInfo & WithLanguage> & { address?: Address }

/**
 * Pilot update model
 *
 * Strong identification fields are not included, data is combined in the backend.
 */
export type UpdatePilot =
  | (UpdatePilotBase & AuthStrong)
  | (UpdatePilotBase & AuthNone & Partial<WithLanguage> & SomeConsents)

export type UpdatePilotBase = { pilotIdentifier: string } & Partial<ContactInfo> & { address?: Address }

/**
 * Operator registration period extension model
 */
export type ExtendOperator = ExtendOperatorBase & { userLanguage: UserLanguage }
export type ExtendOperatorBase =
  | (ExtendBase & AuthStrong & KindPerson)
  | (ExtendBase & AuthNone & KindPerson)
  | (ExtendBase & AuthNone & KindOrganization)

// Email only for sending a receipt
type ExtendBase = { registrationPeriod: Registration.Period; operatorIdentifier: string; email: string }
