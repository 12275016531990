import operations_ from './operations.json'
import { Endpoints } from './endpoints'

export { Endpoints }

export type Method = 'delete' | 'get' | 'patch' | 'post' | 'put'

export type Parameter = {
  name: string
  in: 'path' | 'query'
  required: boolean
  schema: {
    type: 'string' // Expand?
  }
}

export type Operation = {
  url: string
  method: Method
  operationId: keyof Endpoints
  parameters?: Parameter[]
  requestBody?: string
  responseBody?: string
}

export const operations = operations_ as Operation[]
