import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { OptionsType, Styles, ValueType } from 'react-select'
import { makeAsyncSelect } from 'react-select/async'
import { SelectComponents as AllRawComponents } from 'react-select/src/components'
import styled, { css } from 'styled-components'
import { Validation } from 'traficom-registry-shared'
import { theme } from '../../../styling/theme'

declare module 'react-select/src/components/containers' {
  export interface ContainerState {
    isDisabled: boolean
    isRtl: boolean
    isFocused: boolean // Expands library type
  }
}

type AllComponents<T> = AllRawComponents<T, false>
export type SelectComponents<T extends Value = string> = Pick<AllComponents<SelectOption<T>>, 'Option' | 'SingleValue'>

type Value = number | string

export type SelectOption<T extends Value = string> = {
  value: T
  label: string
}

export type ReactSelectState = {
  isClearable: boolean
  isDisabled: boolean
  isLoading: boolean
  isRtl: boolean
  isSearchable: boolean
}

export type ReactSelectProps<T extends Value = string> = SelectFieldProps<T> & {
  id?: string
  label?: string
  name: string
  onChange: (value: T) => void
  selected?: T | null
  state?: Partial<ReactSelectState>
  errorMessage?: string
  error?: boolean
}

export type SelectFieldProps<T extends Value = string> = {
  components?: Partial<SelectComponents<T>>
  options: Options<T>
  noOptionsMessage?: (param: { inputValue: string }) => string
}

type Options<T extends Value> = OptionsType<SelectOption<T>> | LoadOptions<T>
type LoadOptions<T extends Value> = (inputValue: string) => OptionsType<SelectOption<T>>

const ReactSelectRoot = styled.div<{ isDirty: boolean }>`
  position: relative;
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};
  ${({ isDirty }) =>
    isDirty &&
    css`
      > label {
        transform: translate(${props => props.theme.spacing(2)}, 7px);
        font-size: 13px;
      }
    `}
`

const StyledHelperContainer = styled.div`
  display: block;
  margin-top: ${props => props.theme.spacing(0.5)};
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  font-size: 1rem;
  line-height: 1rem;
  min-height: 1rem;
`

const StyledError = styled.span`
  color: ${props => props.theme.palette.error};
`

const StyledLabel = styled.label`
  pointer-events: none; // Allow label clicks to open menu
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform: translate(${props => props.theme.spacing(2)}, 17px);
  transition: transform 130ms cubic-bezier(0, 0.38, 0.51, 1.03) 0ms, font-size 130ms cubic-bezier(0, 0.38, 0.51, 1.03) 0ms;
  color: ${props => props.theme.palette.grey[700]};
`

type CustomStyles<T extends Value> = Partial<Styles<SelectOption<T>, false>>

const selectStyles: CustomStyles<Value> = {
  control: (provided, { isFocused, selectProps: { error } }) => ({
    ...provided,
    borderRadius: 0,
    height: 56,
    borderColor: 'none',
    border: 'none',
    boxShadow: 'none',
    borderBottom: `2px solid ${theme.palette.grey[600]}`,
    outline: `2px solid ${isFocused ? theme.palette.focus : error ? theme.palette.error : 'transparent'}`,
    transition: 'outline 100ms cubic-bezier(0.2, 0, 0.38, 0.9)',
    '&:hover': {},
  }),
  indicatorSeparator: () => ({}),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: '1.25rem',
    marginTop: 16,
    fontSize: '1rem',
    color: theme.palette.text.dark,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
  }),
  container: (provided, { isFocused }) => ({
    ...provided,
    '& + label': {
      transform: isFocused ? `translate(${theme.spacing(2)}, 7px)` : undefined,
      fontSize: isFocused ? 13 : undefined,
    },
  }),
}

function isAsync<T extends Value = string>(options: Options<T>): options is LoadOptions<T> {
  return typeof options === 'function'
}

function ReactSelect<T extends Value = string>(props: ReactSelectProps<T>): JSX.Element {
  const { components, name, onChange, options, state, selected, id, label, errorMessage, error } = props
  const { t } = useTranslation('common')

  const Comp = isAsync(options) ? makeAsyncSelect(Select) : Select

  const change = useCallback(
    (option: ValueType<SelectOption<T>, false>) => {
      onChange((option as SelectOption<T>)?.value)
    },
    [onChange],
  )
  const hasValue = !Validation.isEmptyValue(selected)
  const selectValue = hasValue
    ? (isAsync(options) ? options(selected as string) : options).find(o => o.value === selected)
    : null

  return (
    <ReactSelectRoot isDirty={hasValue}>
      <Comp
        id={id}
        error={!!errorMessage || error}
        {...state}
        name={name}
        aria-label={t('search_for', { subject: label })}
        styles={selectStyles as CustomStyles<T>}
        components={components}
        loadOptions={isAsync(options) ? async value => options(value) : undefined}
        options={isAsync(options) ? undefined : options}
        onChange={change}
        value={selectValue}
        placeholder=""
        loadingMessage={() => t('loading')}
        noOptionsMessage={props.noOptionsMessage ?? (() => t('no_options'))}
      />
      <StyledLabel htmlFor={id}>{state?.isClearable ? `${label} (${t('common:not_required')})` : label}</StyledLabel>
      {errorMessage && (
        <StyledHelperContainer>
          <StyledError>{errorMessage}</StyledError>
        </StyledHelperContainer>
      )}
    </ReactSelectRoot>
  )
}

export default ReactSelect
