import styled from 'styled-components'

export type SectionContainerProps = {
  backgroundImageUrl?: string
}

export type SectionWithBackgroundGradientProps = {
  angle?: number
  gradientStart: string
  gradientEnd: string
}

export const SectionWithBackground = styled.section<SectionContainerProps>`
  background-color: #1f2223;
  background-image: url(${props => props.backgroundImageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${p => p.theme.mediaquery('sm')} {
    min-height: 100%;
  }
`

export const SectionWithBackgroundGradient = styled.section<SectionWithBackgroundGradientProps>`
  background: ${p => p.gradientStart};
  background: linear-gradient(
    ${p => (p.angle ? `${p.angle}deg` : '45deg')},
    ${p => p.gradientStart} 0%,
    ${p => p.gradientEnd} 100%
  );
  ${p => p.theme.mediaquery('sm')} {
    min-height: 100%;
  }
`

export default SectionWithBackground
