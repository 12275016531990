import React, { ReactElement } from 'react'
import MarkdownView from 'react-showdown'
import styled from 'styled-components'
import { palette } from '../styling/palette'

export const Markdown = (props: { className?: string; children: string }): ReactElement => (
  <StyledView className={props.className} markdown={props.children} />
)

const StyledView = styled(MarkdownView)`
  a,
  a:visited {
    color: ${palette.primary[700]};
  }

  a:focus,
  a:hover {
    color: ${palette.grey[900]};
  }
`
