import { Phone } from '../model'
import { Build, createValidationFn, createValidator } from './util'

export const allUppercase = createValidator.string(value => {
  return value.toUpperCase() === value ? undefined : `Must be all uppercase. got ${value}`
})

export const validateEmail = createValidator.string(email => {
  // Regex from: https://stackoverflow.com/a/742588
  if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) {
    return `Email ${email} is not valid email`
  }
  return undefined
})

export const allowOnlyNumbers = createValidator.string(s => {
  if (!/^\d+$/.test(s)) {
    return 'Must contain only numbers'
  }
  return undefined
})

export const allowOnlyCharacters = createValidator.string(s => {
  if (/\d/.test(s)) {
    return 'Must contain only characters'
  }
  return undefined
})

export const oneOf = <T>(options: Readonly<T[]>): Build<T> =>
  createValidationFn<T>(input => (options.includes(input as T) ? undefined : `Must be one of: ${options.join(', ')}.`))

export const exact = <T>(value: T): Build<T> =>
  createValidationFn<T>(input => (input === value ? undefined : `Must be exactly: ${value}.`))

export const typeOf = <T>(typeName: string): Build<T> =>
  createValidationFn<T>(input => (typeof input === typeName ? undefined : `Must have type: ${typeName}`))

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}$/
export const isIsoDate = createValidator.string(s => (DATE_PATTERN.test(s) ? undefined : 'Must be a valid ISO date'))

export const isIsoDateTime = createValidator.string(s =>
  Number.isNaN(new Date(s).valueOf()) ? 'Must be a valid ISO date time' : undefined,
)

export const phoneNumber = createValidator.string(s => {
  if (!Phone.PATTERN.test(s)) {
    return 'Must be telephone number'
  }
  return undefined
})

/** Simple regular expression for Finnish personal identification number, no date validity checks */
const PERSON_ID_PATTERN = /^\d{6}[-+A]\d{3}[\dA-Z]$/
export const isFinnishPersonId = createValidator.string(s =>
  PERSON_ID_PATTERN.test(s) ? undefined : 'Must be a valid Finnish personal identification number',
)

export const isYtunnus = createValidator.string(s => {
  if (!/^[\d]{7}-[\d]$/.test(s)) {
    return 'Must be a Finnish organization identifier'
  }
  return undefined
})

export const notEmpty = createValidator.string(s => {
  if (!s || s.trim() === '') {
    return 'Must be not empty'
  }
  return undefined
})

export const noExtraWhitespace = createValidator.string(input =>
  input.trim() === input ? undefined : 'Must not start with or end in whitespace',
)

export const isChecked = createValidator.boolean(s => {
  if (!s) {
    return 'Must be checked'
  }
  return undefined
})

export const minLength = (length: number): Build<string> =>
  createValidator.string(str => {
    if (str.length < length) {
      return `String is shorter than ${length}`
    }
    return undefined
  })

export const maxLength = (length: number): Build<string> =>
  createValidator.string(str => {
    if (str.length > length) {
      return `String is longer than ${length}`
    }
    return undefined
  })

export const isOperatorIndentifier = createValidator.string(s => {
  if (!/^FIN{1}\w{13}$/.test(s)) {
    return 'Must be valid operator identifier'
  }
  return undefined
})

export const isPilotIdentifier = createValidator.string(s =>
  /^FIN-RP-\d{11,12}$/.test(s) ? undefined : 'Must be valid pilot identifier',
)

export const isUUID = createValidator.string(s => {
  // https://stackoverflow.com/a/13653180
  if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(s)) {
    return 'Must be valid UUID'
  }
  return undefined
})
