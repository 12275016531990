export const palette = {
  primary: {
    [100]: '#e5edff',
    [200]: '#99b9ff',
    [300]: '#2a8dff',
    [500]: '#0034ac',
    [700]: '#002988',
  },
  secondary: '#3dbeaf',
  grey: {
    [100]: '#fafafa',
    [300]: '#f1f1f1',
    [500]: '#dddddd',
    [600]: '#949494',
    [700]: '#6c6c6c',
    [900]: '#282828',
  },
  white: '#ffffff',
  black: '#000000',
  error: '#a22027',
  focus: '#0f62fe',
  text: {
    dark: '#282828', // Grey 900
    light: '#ffffff', // White
  },
} as const

export type Palette = typeof palette
