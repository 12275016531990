import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from '../layout-user-mgmt'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { api } from '../../services/api'
import { Comp } from '../../utils/component'
import { Form, Submit, SubmitButton, TextField, useFormErrorHandler } from '../../ui-user-mgmt/form'
import { getRedirectRequest } from './MsalInstance'
import { FormValues } from '../../utils/types'
import { UserMgmt as U } from 'traficom-registry-shared'
import { validators } from 'traficom-registry-shared'
import styled from 'styled-components'

const organizationDefaults = (): FormValues<{ mail: string } & U.Organization> => ({
  mail: '',
  name: '',
  businessId: '',
  contactName: '',
  address: '',
  postalCode: '',
  municipality: '',
  phoneNumber: '',
  contactPhoneNumber: '',
  astraGovernmentOrganization: '',
})

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const FormContainer = styled.div`
  width: 100%;
`

export const CreateOrganizationOnBehalf: Comp = () => {
  const { t } = useTranslation()
  const handleError = useFormErrorHandler<{ mail: string } & U.Organization>()

  const tokenRequest = getRedirectRequest('/#/create-organization')
  const { acquireToken } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  const submit: Submit<{ mail: string } & U.Organization> = async (values, helpers) => {
    const authResult = await acquireToken(InteractionType.Redirect, tokenRequest)
    const token = authResult ? authResult.accessToken : ''
    const result = await api.createOrganizationOnBehalf({ ...values }, { accessToken: token })
    if (result.success) {
      window.location.href = '/#/admin/organizations'
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <PageContainer>
      <AuthenticatedTemplate>
        <FormAndTitleContainer>
          <Page.Content title={t('user_management:create_organization')}>
            <FormContainer>
              <Form<{ mail: string } & U.Organization>
                initialValues={organizationDefaults()}
                onSubmit={submit}
                validate={validators.createOrganizationOnBehalf}
              >
                <TextField label={t('personal_data:email')} name="mail" disabled={false} />
                <TextField label={t('personal_data:name')} name="name" disabled={false} />
                <TextField label={t('personal_data:company_id')} name="businessId" disabled={false} />
                <TextField label="Yhteyshenkilön nimi" name="contactName" disabled={false} />
                <TextField label={t('personal_data:address')} name="address" disabled={false} />
                <TextField label={t('personal_data:postal_code')} name="postalCode" disabled={false} />
                <TextField label={t('personal_data:municipality')} name="municipality" disabled={false} />
                <TextField label={t('personal_data:telephone')} name="phoneNumber" disabled={false} />
                <TextField label="Yhteyshenkilön puhelinnumero" name="contactPhoneNumber" disabled={false} />
                <SubmitButton labelKey="user_management:create_organization" />
              </Form>
            </FormContainer>
          </Page.Content>
        </FormAndTitleContainer>
      </AuthenticatedTemplate>
    </PageContainer>
  )
}
