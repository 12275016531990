export const PATTERN = /^[+]?[- \d]*(\(?[\d]{1,4}\))?[- \d]+$/

export const FINNISH_COUNTRY_CODE = 358

export const clean = (raw: string): string => raw.replace(/[- ()]/g, '')

const formatInternational = (num: string): string =>
  num.startsWith('+')
    ? num
    : num.startsWith('00')
    ? `+${num.slice(2)}`
    : num.startsWith('0')
    ? `+${FINNISH_COUNTRY_CODE}${num.slice(1)}`
    : `+${FINNISH_COUNTRY_CODE}${num}` // This is quite suspect.

/** Convert to the international format that uses plus before country code. */
export const toInternational = (num: string): string => formatInternational(clean(num))
