import { css } from 'styled-components'

const layoutMixins = {
  flexStretch: css`
    flex: 1 0;
  `,
  // Source: https://stackoverflow.com/a/19758620
  screenReaderOnly: css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  `,
}

const formMixins = {}

const disabledButtonMixin = css`
  background-color: ${props => props.theme.palette.grey[500]};
  color: ${props => props.theme.palette.grey[700]};
  border: 1px solid ${props => props.theme.palette.grey[500]};
  cursor: not-allowed;
  pointer-events: none;
`

const buttonMixins = {
  disabledButtonMixin,
  buttonBase: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    color: inherit;
    line-height: 1; // Remove extra height from link buttons
    font-size: inherit;
    font-family: inherit;
    font-weight: 700;
    &:focus {
      outline: ${p => p.theme.borders['m']} solid ${props => props.theme.palette.focus};
    }
    &:active {
      outline: ${p => p.theme.borders['m']} solid ${props => props.theme.palette.focus};
    }
    &:disabled {
      ${disabledButtonMixin}
    }
  `,
  primaryButton: css`
    color: ${props => props.theme.palette.text.light};
    background-color: ${props => props.theme.palette.primary[500]};
    margin-top: ${props => props.theme.spacing(0.5)};
    margin-bottom: ${props => props.theme.spacing(0.5)};
    margin-right: ${props => props.theme.spacing(0.5)};
    padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(1.5)};
    border: none;
    border-radius: ${props => props.theme.spacing(3)};
    &:hover {
      background-color: ${props => props.theme.palette.primary[700]};
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 ${p => p.theme.borders['m']} ${props => props.theme.palette.focus};
    }
    &:active {
      outline: none;
      box-shadow: 0 0 0 ${p => p.theme.borders['m']} ${props => props.theme.palette.focus};
      background-color: ${props => props.theme.palette.primary[300]};
    }
    transition: all linear 100ms;
  `,
  secondaryButton: css`
    color: ${props => props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.grey[100]};
    margin-top: ${props => props.theme.spacing(0.5)};
    margin-bottom: ${props => props.theme.spacing(0.5)};
    margin-right: ${props => props.theme.spacing(0.5)};
    padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(1.5)};
    border: ${p => p.theme.borders['s']} solid ${props => props.theme.palette.grey[500]};
    border-radius: ${props => props.theme.spacing(3)};
    &:hover {
      background-color: ${props => props.theme.palette.grey[100]};
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 ${p => p.theme.borders['m']} ${props => props.theme.palette.focus};
    }
    &:active {
      outline: none;
      box-shadow: 0 0 0 ${p => p.theme.borders['m']} ${props => props.theme.palette.focus};
      background-color: ${props => props.theme.palette.grey[500]};
    }
    transition: all linear 100ms;
  `,
  negativeButton: css`
    color: ${props => props.theme.palette.text.light};
    background-color: ${props => props.theme.palette.black};
    margin-top: ${props => props.theme.spacing(0.5)};
    margin-bottom: ${props => props.theme.spacing(0.5)};
    margin-right: ${props => props.theme.spacing(0.5)};
    padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(1.5)};
    border: ${p => p.theme.borders['s']} solid ${props => props.theme.palette.black};
    border-radius: ${props => props.theme.spacing(3)};
    &:hover {
      background-color: ${props => props.theme.palette.grey[900]};
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 ${p => p.theme.borders['m']} ${props => props.theme.palette.focus};
    }
    &:active {
      outline: none;
      box-shadow: 0 0 0 ${p => p.theme.borders['m']} ${props => props.theme.palette.focus};
      background-color: ${props => props.theme.palette.grey[700]};
    }
    transition: all linear 100ms;
  `,
}

const mixins = {
  layout: {
    ...layoutMixins,
  },
  form: {
    ...formMixins,
  },
  button: {
    ...buttonMixins,
  },
}

export { mixins }
