import React from 'react'
import { Link, useParams } from 'react-router-dom'
import EmptyState from '../../ui-common/empty-state/empty-state'
import { Page } from '../layout'
import { useTranslation } from 'react-i18next'
import { Routing } from 'traficom-registry-shared'
import Button from '../../ui-common/button/button'

const isNonGenericError = (error: unknown): error is Routing.ErrorPageError =>
  typeof error === 'string' && Routing.ErrorPageParams.includes(error as Routing.ErrorPageError)

type ErrorStateProps = {
  error: Routing.ErrorPageError
}

export const ErrorState: React.FC<ErrorStateProps> = ({ error }) => {
  const { t } = useTranslation()
  return (
    <EmptyState state="error" title={t(`errorpage:${error}-title`)} subtitle={t(`errorpage:${error}-description`)}>
      <Button as={Link} to={Routing.patterns.frontPage}>
        {t('errorpage:return_to_frontpage')}
      </Button>
    </EmptyState>
  )
}

type ErrorPageProps = {
  defaultError?: Routing.ErrorPageError
}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({ defaultError }) => {
  const { t } = useTranslation()
  const { errorParam = defaultError } = useParams<{ errorParam?: Routing.ErrorPageError }>()
  const error = isNonGenericError(errorParam) ? errorParam : 'generic'

  return <Page.Content title={t('errorpage:generic-title')}>{error && <ErrorState error={error} />}</Page.Content>
}

export default ErrorPage
