import React from 'react'
import styled from 'styled-components'
import { mixins } from '../../styling/mixins'

export type ButtonProps = {
  $fullWidth?: boolean
  as?: React.ElementType | keyof JSX.IntrinsicElements
  size?: 'small' | undefined
  variant?: 'primary' | 'secondary' | 'negative'
}

const ButtonBase = styled.button`
  ${mixins.button.buttonBase}
`

const Button = styled.button<ButtonProps>`
  ${mixins.button.buttonBase}
  ${props =>
    props.variant === 'secondary'
      ? mixins.button.secondaryButton
      : props.variant === 'negative'
      ? mixins.button.negativeButton
      : mixins.button.primaryButton}
  ${props => props.$fullWidth && 'width: 100%'};
  ${props => props.size === 'small' && 'padding:' + props.theme.spacing(1)};
`

export default Button
export { ButtonBase }
