type Scalar = boolean | number | string

type ScalarValidator<T extends Scalar> = (value: T) => string | undefined
type AnyValidator = (value: unknown) => string | undefined
type Guard<T> = (value: unknown) => value is T

export type Errors<T> = T extends Scalar ? string | undefined : ObjectErrors<T>
type ObjectErrors<T> = Partial<{ [K in keyof T]: Errors<T[K]> }>

export type Value<T> = (T extends Scalar ? T : Partial<T>) | undefined
export type Validator<T> = (value: Value<T>) => Errors<T> | undefined

type Schema<T> = T extends Scalar ? Validator<T> : Partial<{ [K in keyof T]: Builder<T[K]> }>
export type Builder<T> = (value: Value<T>) => Schema<T>

export type ValidationFn<T> = (value: Value<T>) => string | undefined
export type Build<T> = (errorKey?: string) => ValidationFn<T>
export type Create<T> = () => ValidationFn<T>

export const isEmptyValue = (val: unknown): boolean => val === undefined || val === null || val === ''

export function clearEmptyValues<T>(obj: T): Partial<T> {
  const result: Partial<T> = {}
  Object.entries(obj).forEach(([key, val]) => {
    if (isEmptyValue(val)) {
      return
    }

    if (typeof val === 'object') {
      const children = clearEmptyValues(val)
      if (Object.keys(children).length > 0) {
        result[key as keyof T] = children as T[keyof T]
      }
    } else {
      result[key as keyof T] = val
    }
  })

  return result
}

export const createValidationFn =
  <T>(validationFn: ValidationFn<T>) =>
  (contextualErrorKey?: string): ValidationFn<T> =>
    contextualErrorKey ? value => (validationFn(value) ? contextualErrorKey : undefined) : validationFn

const createTypedValidator =
  <T extends Scalar>(guard: Guard<T>) =>
  (base: ScalarValidator<T>) =>
  (contextualErrorKey?: string): AnyValidator => {
    const validator: AnyValidator = value => {
      if (!guard(value)) {
        return 'Incorrect type'
      }

      return base(value as T)
    }

    return contextualErrorKey ? value => (validator(value) ? contextualErrorKey : undefined) : validator
  }

export const createValidator = {
  boolean: createTypedValidator((value): value is boolean => typeof value === 'boolean'),
  number: createTypedValidator((value): value is number => typeof value === 'number'),
  string: createTypedValidator((value): value is string => typeof value === 'string'),
}

export const chain =
  <T>(...fns: ValidationFn<T>[]): ValidationFn<T> =>
  value =>
    fns.reduce((prev: string | undefined, fn) => prev ?? fn(value), undefined)

export const optional =
  <T>(fn: Create<T>): Create<T> =>
  () =>
  value =>
    value === undefined ? undefined : fn()(value)

export const optionalObject =
  <T extends object>(builder: Builder<T>): Builder<T> =>
  value =>
    value === undefined ? ({} as Schema<T>) : builder(value)

/**
 * Validate data based on a validator builder.
 *
 * Builder functions enable simple, composable validators, but also allow for more complex, dependent use cases.
 */
export const validate = <T>(builder: Builder<T>, value: Value<T>): Errors<T> | undefined => {
  const schema = builder && builder(value)
  if (!schema) {
    return undefined
  }

  if (typeof schema === 'function') {
    // Schema is a validator function and the value is some scalar
    return (schema as Validator<T>)(value)
  }

  // Schema and value are both objects
  const errors: ObjectErrors<T> = {}

  const validateProp = <Name extends keyof T>(name: Name, propBuilder: Builder<T[Name]>) => {
    const propErrors = validate(propBuilder, (value as Partial<T>)?.[name] as Value<T[Name]>)
    if (propErrors) {
      errors[name] = propErrors
    }
  }

  Object.entries(schema).forEach(([name, propBuilder]) => {
    validateProp(name as keyof T, propBuilder as Builder<T[keyof T]>)
  })

  return clearEmptyValues(errors) as Errors<T>
}

export const create =
  <T>(schema: Builder<T>): Validator<T> =>
  value =>
    validate(schema, value)
