import React, { useContext, useEffect, useState } from 'react'
import { useMsalAuthentication } from '@azure/msal-react'
import { getRedirectRequest } from '../../components/user-mgmt/MsalInstance'
import { TokenClaims } from '@azure/msal-common'
import { InteractionType } from '@azure/msal-browser'
import styled from 'styled-components'
import { Comp } from '../../utils/component'
import LanguageSelector from '../language-selector/language-selector'
import { useTranslation } from 'react-i18next'
import { Identification, Routing } from 'traficom-registry-shared'
import { RegistrationContext } from '../../state/registration'
import { Link } from 'react-router-dom'
import { HeaderMenu, HeaderMenuItem, HeaderMenuToggle } from '../header-menu/header-menu'
import { ButtonBase } from '../button/button'
import { ReactComponent as Menu } from '../../images/menu.svg'
import { ReactComponent as X } from '../../images/x.svg'
import UserMenu from '../user-menu/user-menu'
import SignInSignOutButton from '../../components/user-mgmt/SignInSignOutButton'
import Dropdown from '../dropdown/dropdown'

const getDataText = (i: Identification.IdentifiedState) =>
  i.mode === 'EMAIL' ? i.data.email : `${i.data.lastName}, ${i.data.firstNames}`

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing(0.5)} 0;
  width: 100%;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AppHeading = styled.span`
  color: ${p => p.theme.palette.black};
  font-size: 1.5rem;
  margin: 0;
`
const Left = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
`

const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.palette.black};
  margin-left: 1.5em;
`

const StyledMicroText = styled.span`
  display: block;
  font-size: 11px;
  margin-bottom: ${p => p.theme.spacing(0.5)};
`

const ToggleInMobile = styled.div`
  display: none;

  ${p => p.theme.mediaquery('sm')} {
    display: inline-block;
  }
`

const UserMenuItem = styled(ButtonBase)`
  margin: ${p => p.theme.spacing(0.2)} 0;
  color: ${props => props.theme.palette.text.dark};
  font-size: 1rem;
  white-space: nowrap;
  text-align: left;
  padding-left: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const StyledHeaderMenuToggle = styled(HeaderMenuToggle)`
  padding-right: 0;

  & > svg {
    height: 22px;
    width: 22px;
    margin-left: ${p => p.theme.spacing(0.5)};
  }
`

const MOBILE_MENU_ID = 'mobile_menu'

export const TopBar: Comp = () => {
  const [roles, setRoles] = useState<string[]>([])
  const tokenRequest = getRedirectRequest('/#/user-profile')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    if (result) {
      const claims = result.idTokenClaims as TokenClaims
      setRoles(claims.roles ?? [])
    }
  }, [error, result])

  const [isOpen, setIsOpen] = React.useState(false)
  const { t, i18n } = useTranslation()

  const { state } = useContext(RegistrationContext)
  const { identification } = state
  const isIdentified = identification.identified

  const logout = () => {
    window.location.href = `/api/logout?lang=${i18n.language}`
  }

  const identifiedAs = identification.identified ? getDataText(identification) : ''

  const StyledButtonBase = styled(ButtonBase)`
    height: 100%;
    padding: ${p => p.theme.spacing(1)};
    white-space: nowrap;
    color: ${props => props.theme.palette.text.dark};
    font-weight: 400;
  `

  return (
    <>
      <BarContainer>
        <Left>
          <StyledLink to={Routing.patterns.frontPage}>
            <AppHeading>Fintraffic ID</AppHeading>
          </StyledLink>
          {
            <ToggleInMobile>
              <StyledLink to={Routing.patterns.organizations}>{t('user_management:organizations')}</StyledLink>
            </ToggleInMobile>
          }
          {roles.some(role => role === 'ans_admin') && (
            <ToggleInMobile>
              <Dropdown id="adminMenu" toggleText={t('user_management:fid_admin')} toggleAs={StyledButtonBase} chevron>
                <StyledLink to={Routing.patterns.adminOrganizations}>{t('user_management:organizations')}</StyledLink>
                <StyledLink to={Routing.patterns.adminUsers}>{t('user_management:users')}</StyledLink>
                <StyledLink to={Routing.patterns.adminWorkQueue}>{t('user_management:work_queue')}</StyledLink>
                <StyledLink to={Routing.patterns.adminAdGroups}>{t('user_management:ad_group_settings')}</StyledLink>
              </Dropdown>
            </ToggleInMobile>
          )}
        </Left>
        <Right>
          <ActionContainer>
            {isIdentified && (
              <ToggleInMobile>
                <UserMenu loginText={identifiedAs} logout={logout} logoutText={t('common:logout')} />
              </ToggleInMobile>
            )}
            <ToggleInMobile>
              <SignInSignOutButton />
            </ToggleInMobile>
            <ToggleInMobile>
              <LanguageSelector />
            </ToggleInMobile>

            <StyledHeaderMenuToggle menuId={MOBILE_MENU_ID} onClick={() => setIsOpen(!isOpen)} isActive={isOpen}>
              {t('menu')}
              {isOpen ? <X role="presentation" /> : <Menu role="presentation" />}
            </StyledHeaderMenuToggle>
          </ActionContainer>
        </Right>
      </BarContainer>
      {isOpen && (
        <HeaderMenu id={MOBILE_MENU_ID}>
          {isIdentified && (
            <HeaderMenuItem>
              <UserMenuItem onClick={logout}>
                <StyledMicroText>{identifiedAs}</StyledMicroText>
                {t('common:logout')}
              </UserMenuItem>
            </HeaderMenuItem>
          )}
          <>
            <HeaderMenuItem>
              <StyledLink to={Routing.patterns.organization}>{t('user_management:organization_admin')}</StyledLink>
            </HeaderMenuItem>
            <HeaderMenuItem>
              {roles.some(role => role === 'ans_admin') && (
                <Dropdown
                  id="adminMenuMobile"
                  toggleText={t('user_management:fid_admin')}
                  toggleAs={StyledButtonBase}
                  chevron
                >
                  <StyledLink to={Routing.patterns.adminOrganizations}>{t('user_management:organizations')}</StyledLink>
                  <StyledLink to={Routing.patterns.adminUsers}>{t('user_management:users')}</StyledLink>
                  <StyledLink to={Routing.patterns.adminWorkQueue}>{t('user_management:work_queue')}</StyledLink>
                  <StyledLink to={Routing.patterns.adminAdGroups}>{t('user_management:ad_group_settings')}</StyledLink>
                </Dropdown>
              )}
            </HeaderMenuItem>

            <HeaderMenuItem>
              <SignInSignOutButton />
            </HeaderMenuItem>

            <HeaderMenuItem>
              <LanguageSelector />
            </HeaderMenuItem>
          </>
        </HeaderMenu>
      )}
    </>
  )
}
