import { Api, Validation } from 'traficom-registry-shared'

type WithBearer<Path> = { accessToken: string } & (Path extends undefined ? {} : Path)

type Endpoints = Api.Endpoints & Api.UserMgmtEndpoints
type EndpointName = Api.Name | Api.UserMgmtEndpointName
type Routes = typeof routes

export type Endpoint<Name extends EndpointName = EndpointName> = Endpoints[Name]
export const routes = { ...Api.routes, ...Api.userMgmtRoutes }
export type Route<Name extends EndpointName = EndpointName> = Routes[Name]

export type ApiResult<EP extends Endpoint> = Result<EP['result'], FailureData<EP['body']>>
export type Result<Success, Failure> = { success: true; data: Success } | { success: false; data: Failure }
export type FailureData<Body> = { message?: string; errors?: Validation.Errors<Body> }

type HandlerBearer<EP extends Endpoint> = (body: EP['body'], params: WithBearer<EP['path']>) => Promise<ApiResult<EP>>
type HandlerPath<EP extends Endpoint> = (body: EP['body'], params: EP['path']) => Promise<ApiResult<EP>>
type HandlerBody<EP extends Endpoint> = (body: EP['body']) => Promise<ApiResult<EP>>
type HandlerNone<EP extends Endpoint> = () => Promise<ApiResult<EP>>

type Handler<EP extends Endpoint> = EP['security'] extends 'bearer'
  ? HandlerBearer<EP>
  : EP['path'] extends undefined
  ? EP['body'] extends undefined
    ? HandlerNone<EP>
    : HandlerBody<EP>
  : HandlerPath<EP>

export type Handlers = { [Name in EndpointName]: Handler<Endpoint<Name>> }
