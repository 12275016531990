import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RegistrationContext } from '../../state/registration'
import { Button } from '../../ui-common'
import EmptyState from '../../ui-common/empty-state/empty-state'
import { isDuring } from '../../services/announcement-service'

export const ServiceBreak: React.FC = props => {
  const { t } = useTranslation()
  const { announcements } = useContext(RegistrationContext).state
  const { children } = props
  const unavailable = announcements.values.find(a => a.unavailable && isDuring(new Date(), a.unavailable))

  if (unavailable)
    return (
      <EmptyState state={'error'}>
        <Button onClick={() => window.location.assign('/')}>{t('errorpage:return_to_frontpage')}</Button>
      </EmptyState>
    )
  return <>{children}</>
}

export default ServiceBreak
