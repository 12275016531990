import { Route, Switch } from 'react-router-dom'
import { Routing } from 'traficom-registry-shared'
import { CookiePolicyPage } from '../components/info-page'
import Frontpage from '../components/frontpage/frontpage'
import ErrorPage from '../components/error/error-page'
import { Comp } from '../utils/component'
import { UserProfile } from '../components/user-mgmt/user-profile'
import { JoinOrganization } from '../components/user-mgmt/join-organization'
import { CreateOrganization } from '../components/user-mgmt/create-organization'
import { Organizations } from '../components/user-mgmt/organizations'
import { CreateOrganizationOnBehalf } from '../components/user-mgmt/create-org-on-behalf'
import { OrganizationAdmin } from '../components/user-mgmt/org-admin'
import { AdminWorkqueue } from '../components/user-mgmt/admin-workqueue'
import { AdminUsers } from '../components/user-mgmt/admin-users'
import { AdminOrganizations } from '../components/user-mgmt/admin-organizations'
import { AdminAdGroups } from '../components/user-mgmt/admin-adgroups'

export const Routes: Comp = () => (
  <Switch>
    <Route component={Frontpage} path={Routing.patterns.frontPage} exact />
    <Route component={CookiePolicyPage} path={Routing.patterns.cookieInfo} exact />
    <Route component={UserProfile} path="/user-profile" />
    <Route component={JoinOrganization} path="/join-organization" />
    <Route component={CreateOrganization} path="/create-organization" />
    <Route component={CreateOrganizationOnBehalf} path="/admin/create-org-onbehalf" />
    <Route component={OrganizationAdmin} path="/org-admin/:id" />
    <Route component={Organizations} path="/organizations" />
    <Route component={AdminWorkqueue} path="/admin/workqueue" />
    <Route component={AdminUsers} path="/admin/users" />
    <Route component={AdminOrganizations} path="/admin/organizations" />
    <Route component={AdminAdGroups} path="/admin/adgroups" />
    <Route component={ErrorPage} path={Routing.patterns.errorPage} />
    <Route render={() => <ErrorPage defaultError="not-found" />} />
  </Switch>
)
