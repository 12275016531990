import React, { ReactNode } from 'react'
import Loader from '../loader/loader'
import styled from 'styled-components'

interface LoadingSpinnerProps {
  isLoading: boolean | undefined
  children: ReactNode
}

const LoadingSpinnerContainer = styled.div`
  position: relative;
`

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
`

const LoaderContainer = styled.div`
  width: 100px;
  height: 100px;
`

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ isLoading, children }) => {
  return (
    <LoadingSpinnerContainer>
      {children}
      {isLoading && (
        <LoadingOverlay>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </LoadingOverlay>
      )}
    </LoadingSpinnerContainer>
  )
}

export default LoadingSpinner
