import { DefaultTheme } from 'styled-components'
import { palette } from './palette'
import { borders } from './borders'

const factorials = [0.2, 0.4, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8] as const
export type Factor = (typeof factorials)[number]

const SPACING_BASE = 10

export type Breakpoint = 'sm' | 'md' | 'lg'

const breakpointMinWidths: { [k in Breakpoint]: number } = {
  sm: 48,
  md: 64,
  lg: 75,
}

const theme: DefaultTheme = {
  palette,
  spacing: (factor: Factor): string => `${(SPACING_BASE / 16) * factor}rem`,
  mediaquery: (breakpoint: Breakpoint) => `@media (min-width: ${breakpointMinWidths[breakpoint]}em)`,
  borders,
}

export { theme }
