import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../button/button'
import Loader from '../../loader/loader'

type SubmitProps = {
  disabled?: boolean
  labelKey: string
  id?: string
}

export const SubmitButton: React.FC<SubmitProps> = ({ disabled, labelKey, id }) => {
  const { t } = useTranslation()

  const form = useFormikContext()

  return (
    <Button type="submit" disabled={disabled || form.isSubmitting} $fullWidth id={id}>
      {form.isSubmitting ? <Loader size="small" negative /> : t(labelKey)}
    </Button>
  )
}

type CancelProps = {
  to: string
}

export const CancelButton: React.FC<CancelProps> = ({ to }) => {
  const { t } = useTranslation()

  return (
    <Button as={Link} to={to} variant="secondary" $fullWidth>
      {t('common:cancel')}
    </Button>
  )
}
