import { InteractionType } from '@azure/msal-browser'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Routing, UserMgmt as U } from 'traficom-registry-shared'
import { OrgMembership } from 'traficom-registry-shared/out/model/UserProfile'
import { api } from '../../services/api'
import { Button } from '../../ui-user-mgmt'
import { Comp } from '../../utils/component'
import { Page } from '../layout-user-mgmt'
import { getRedirectRequest } from './MsalInstance'

const PageContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid #000;
  display: flex;
  justify-content: center;
`

const PageContent = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 8px;

  & > button,
  a {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`

const OrgTable = styled.table`
  border: 1px solid;
  width: 100%;
`

const OrgHeader = styled.th`
  border: 1px solid;
  padding: 5px;
`

const OrgTd = styled.td`
  border: 1px solid;
  padding: 5px;
`

export const Organizations: Comp = () => {
  const { t: um } = useTranslation('user_management')
  const [organizations, setOrganizations] = useState<U.OrgMembership[]>()
  const [pendingOrganizations, setPendingOrganizations] = useState<U.OrgMembership[]>()

  const tokenRequest = getRedirectRequest('/#/organizations')

  const { acquireToken } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    const updateProfile = async () => {
      const token = await acquireToken(InteractionType.Redirect, tokenRequest)

      if (token) {
        const orgResult = await api.getOrganizations(undefined, { accessToken: token.accessToken })

        let orgs: OrgMembership[] = []

        if (orgResult.success) {
          orgs = orgs.concat(orgResult.data)
        }

        const pendingResult = await api.getOrganizationApplicationStatus(undefined, { accessToken: token.accessToken })

        if (pendingResult.success) {
          const pendingAndApplied = pendingResult.data.orgsApplied.concat(pendingResult.data.orgsCreatePending)

          const pendings = pendingAndApplied.map(
            oa =>
              ({
                organization: oa,
              } as OrgMembership),
          )

          setPendingOrganizations(pendings)

          orgs = orgs.concat(pendings)
        }

        setOrganizations(orgs)
      }
    }

    updateProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContainer>
      <AuthenticatedTemplate>
        <PageContent>
          <Page.Content title={um('user_management:organizations')}>
            <ButtonContainer>
              <Button $fullWidth as={Link} to="join-organization">
                {um('join_organization')}
              </Button>
              <Button $fullWidth variant="secondary" as={Link} to="create-organization">
                {um('user_management:create_organization')}
              </Button>
            </ButtonContainer>

            {!organizations && <p>{um('common:loading')}</p>}

            {organizations && organizations.length > 0 && <p>{um('user_management:organization_list')}</p>}

            {organizations && organizations.length > 0 && (
              <OrgTable>
                <tr>
                  <OrgHeader>{um('user_management:organization_name')}</OrgHeader>
                  <OrgHeader>{um('personal_data:company_id')}</OrgHeader>
                  <OrgHeader>{um('user_management:organization_actions')}</OrgHeader>
                </tr>
                {organizations &&
                  organizations.map(org => (
                    <>
                      {org.organization && org.organization.name && org.organization.businessId && (
                        <tr>
                          {org.organization && org.organization.name && <OrgTd>{org.organization.name}</OrgTd>}
                          {org.organization && org.organization.businessId && <OrgTd>{org.organization.businessId}</OrgTd>}
                          <OrgTd>
                            {org.isOwner && org.organization && org.organization.businessId && (
                              <>
                                <ButtonContainer>
                                  <Button as={Link} to={Routing.patterns.organization + '/' + org.organization.businessId}>
                                    {um('user_management:edit_organization')}
                                  </Button>
                                </ButtonContainer>
                              </>
                            )}
                            {org.numWorkItems !== undefined && org.numWorkItems > 0 && (
                              <p>
                                {um('user_management:work_queue')}: {org.numWorkItems}
                                &nbsp;
                                {org.numWorkItems == 1
                                  ? um('user_management:work_queue_item')
                                  : um('user_management:work_queue_items')}
                                .
                              </p>
                            )}

                            {pendingOrganizations?.find(p => {
                              if (!p.organization || !p.organization.businessId) return false
                              if (!org.organization || !org.organization.businessId) return false

                              return p.organization.businessId == org.organization.businessId
                            }) && um('user_management:waiting_approval')}
                          </OrgTd>
                        </tr>
                      )}
                    </>
                  ))}
              </OrgTable>
            )}

            {organizations && organizations.length == 0 && <p>{um('user_management:no_organizations')}</p>}
          </Page.Content>
        </PageContent>
      </AuthenticatedTemplate>
    </PageContainer>
  )
}
