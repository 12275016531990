import { Codeset, Identification, Locale } from 'traficom-registry-shared'
import { Action } from '../../utils/state-utils/reducer'
import { Choice } from '../../utils/types'
import { AlertLevel } from '../../ui-common/snackbar/snackbar'
import { CodesetStatus } from './registration-context'

export const identify = (payload: Identification.State): Action => ({ type: 'UPDATE_IDENTIFICATION', payload })

export const setCodesets = (
  codeset: Codeset.Name,
  status: CodesetStatus,
  values: Choice[],
  language: Locale.UserLanguage,
): Action => {
  return {
    type: 'UPDATE_CODESET_VALUES',
    payload: {
      codeset,
      status,
      language,
      values,
    },
  }
}

export const addSnackbarMessage = (text: string, level: AlertLevel): Action => ({
  type: 'ADD_SNACKBAR_MESSAGE',
  payload: {
    id: Math.floor(Math.random() * Date.now()),
    text,
    level,
  },
})

export const removeSnackbarMessage = (id: number): Action => ({
  type: 'REMOVE_SNACKBAR_MESSAGE',
  payload: id,
})
