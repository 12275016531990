import React from 'react'
import 'flexboxgrid/dist/flexboxgrid.css'
import classNames from 'classnames'
import styled from 'styled-components'

export interface CommonProps {
  Component?: React.ElementType
  className?: string
  align?: 'center' | 'start' | 'end' | 'top' | 'middle' | 'bottom'
  distribution?: 'around' | 'between'
}

export interface ColProps extends CommonProps {
  xs?: number
  sm?: number
  md?: number
  lg?: number
}

type ColumnClassProps = { xs?: number; sm?: number; md?: number; lg?: number }

const getColumnClasses = ({ xs = 12, sm, md, lg }: ColumnClassProps): string => {
  return classNames({
    [`col-xs-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg,
  })
}

const Row: React.FunctionComponent<CommonProps> = props => {
  const { className, align, distribution, Component = 'div', ...rest } = props
  const alignClass = align && `${align}-xs`
  const distributionClass = distribution && `${distribution}-xs`
  return <Component className={classNames('row', className, alignClass, distributionClass)} {...rest} />
}

const Col: React.FunctionComponent<ColProps> = props => {
  const { className, xs, sm, md, lg, align, distribution, Component = 'div', ...rest } = props
  const colClasses = getColumnClasses({ xs, sm, md, lg })
  const alignClass = align && `${align}-xs`
  const distributionClass = distribution && `${distribution}-xs`
  return <Component className={classNames(className, colClasses, alignClass, distributionClass)} {...rest} />
}

const StyledContainer = styled.div``

type ContainerProps = {
  className?: string
}

const Container: React.FunctionComponent<ContainerProps> = ({ className, children }) => (
  <StyledContainer className={className}>
    <div className={'container container-fluid'}>{children}</div>
  </StyledContainer>
)

export { Row, Col, Container, getColumnClasses }
