import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Routing } from 'traficom-registry-shared'
import { ReactComponent as Fintraffic } from '../../images/Fintraffic_logo.svg'
import { Col, Row, Container } from '../layout/layout'
import { useTranslation } from 'react-i18next'
import { Comp } from '../../utils/component'

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.palette.black};

  padding: ${props => props.theme.spacing(3)} 0;

  ${p => p.theme.mediaquery('sm')} {
    padding: ${props => props.theme.spacing(6)} 0;
  }

  /* Hidden from print, for example to allow printing a receipt without extra banners. */
  @media print {
    visibility: hidden;
  }
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  & > svg {
    max-width: 100%;
  }
`
const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 0.875rem;
`
const StyledListItem = styled.li`
  color: ${p => p.theme.palette.text.light};
`
const StyledFooterLink = styled.a`
  color: ${p => p.theme.palette.text.light};
  text-decoration: none;
  font-weight: 700;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`

const footerLinkList = [
  {
    label: 'footer:privacy-policy',
    to: 'footer:privacy-policy-link',
  },
]

const Footer: Comp = () => {
  const { t } = useTranslation()
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <StyledList>
              <StyledListItem>
                <StyledFooterLink as={Link} to={t(Routing.patterns.cookieInfo)}>
                  {t('common:cookie-policy')}
                </StyledFooterLink>
              </StyledListItem>
              {footerLinkList.map(({ label, to }) => (
                <StyledListItem key={to}>
                  <StyledFooterLink target="_blank" rel="noreferrer" href={t(to)}>
                    {t(label)}
                  </StyledFooterLink>
                </StyledListItem>
              ))}
              <StyledListItem>
                {`${t('footer:contact_information')}: `}
                <StyledFooterLink target="_blank" rel="noreferrer" href={t('footer:contact-email')}>
                  {t('footer:contact-email-label')}
                </StyledFooterLink>
              </StyledListItem>
            </StyledList>
          </Col>
          <Col xs={12} sm={6}>
            <LogoContainer>
              <Fintraffic />
            </LogoContainer>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  )
}
export default Footer
