import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Col, Container, Row } from '../../ui-common/layout/layout'
import SectionWithBackground from '../../ui-common/section/SectionWithBackground'
import { H1 } from '../../ui-common/typography/heading'
import Card from '../../ui-common/card/card'
import backgroundImage from '../../images/BG_web.jpg'
import frontpageImageNg from '../../images/drone_delivery_web.jpg'
import PageMeta from '../metadata/page-meta'

const StyledHeader = styled(H1)`
  color: white;
  font-size: 3rem;
  font-weight: 100;

  ${p => p.theme.mediaquery('sm')} {
    text-align: center;
    font-size: 7rem;
  }
`

const cards = [
  {
    titleKey: 'fid_title',
    contentKey: 'fid_content',
    imageSrc: frontpageImageNg, // TODO
    altTextKey: 'fid_image_alt',
    link: {
      labelKey: 'fid_link_label',
      to: '/user-profile',
    },
  },
]

const Frontpage: React.FunctionComponent = () => {
  const { t } = useTranslation('frontpage')

  return (
    <SectionWithBackground backgroundImageUrl={backgroundImage}>
      <PageMeta />
      <Container>
        <StyledHeader>Fintraffic ID</StyledHeader>
        <Row align="center">
          {cards.map(({ titleKey, contentKey, imageSrc, altTextKey, link: { labelKey, to } }) => (
            <Col sm={4} key={titleKey}>
              <Card
                title={t(titleKey)}
                content={t(contentKey)}
                imageSrc={imageSrc}
                altText={t(altTextKey)}
                link={{
                  label: t(labelKey),
                  to,
                }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </SectionWithBackground>
  )
}

export default Frontpage
