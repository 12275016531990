import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './i18n/i18n'
import MsalInstance from './components/user-mgmt/MsalInstance'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000, {})
}

ReactDOM.render(
  <React.StrictMode>
    <MsalInstance>
      <App />
    </MsalInstance>
  </React.StrictMode>,
  document.getElementById('root'),
)
