import React, { useReducer } from 'react'
import { Codeset, Feature, Identification, Locale } from 'traficom-registry-shared'
import { SnackbarMessage } from '../../ui-common/snackbar/snackbar'
import { Action, reducer } from '../../utils/state-utils/reducer'
import { Choice } from '../../utils/types'

export type CodesetStatus = 'NOT_LOADED' | 'PENDING' | 'LOADED' | 'FAILED'

export type CodesetData = {
  status: CodesetStatus
  values: Choice[]
}

export type Duration = { start: string; end: string }
export type AnnouncementContent = { title: string; text: string }

export type Announcement = {
  active: Duration
  unavailable?: Duration
  content: Record<Locale.UserLanguage, AnnouncementContent>
}
export type Announcements = {
  status: CodesetStatus
  values: Announcement[]
}

export type PageInfo = {
  title?: string
  subTitle?: string
}

export type RegistrationState = {
  features: Partial<Feature.Flags>
  identification: Identification.State
  codesets: Record<Locale.UserLanguage, Partial<Record<Codeset.Name, CodesetData>>>
  snackbarMessages: SnackbarMessage[]
  announcements: Announcements
  page: PageInfo
}

const registrationInitialState: RegistrationState = {
  features: {},
  identification: { identified: false, mode: 'NONE' },
  codesets: { en: {}, fi: {}, sv: {} },
  snackbarMessages: [],
  announcements: { status: 'NOT_LOADED', values: [] },
  page: {},
}

export const RegistrationContext = React.createContext<{
  state: RegistrationState
  dispatch: React.Dispatch<Action>
}>({
  state: registrationInitialState,
  dispatch: () => null,
})

export const RegistrationContextWrapper: React.FunctionComponent = props => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, registrationInitialState)

  return <RegistrationContext.Provider value={{ state, dispatch }}>{children}</RegistrationContext.Provider>
}
