/**
 * Language options
 *
 * Used for both the user interface languages and Traficom service language select
 *
 * Same values as the KdDroneServiceLanguages Traficom codeset
 */
export const languageOptions = ['fi', 'sv', 'en'] as const

export type UserLanguage = (typeof languageOptions)[number]
