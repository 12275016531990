import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMsal, AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionStatus, InteractionType } from '@azure/msal-browser'
import { ProfileData } from './profile-data'
import { api } from '../../services/api'
import { UserMgmt as U } from 'traficom-registry-shared'
import Loader from '../../ui-common/loader/loader'
import { Comp } from '../../utils/component'
import { ErrorState } from '../error/error-page'
import { getRedirectRequest } from './MsalInstance'
import styled from 'styled-components'

type ProfileState = 'failed' | 'fetched' | 'not-fetched'

/* eslint-disable */

const PageContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid #000;
  display: flex;
  justify-content: center;
`

export const UserProfile: Comp = () => {
  const { t } = useTranslation()
  const { inProgress } = useMsal()
  const [graphData, setGraphData] = useState<U.UserProfile>()
  const [orgMembershipStatus, setOrgMembershipStatus] = useState<U.OrganizationApplicationStatus>()
  const [accessToken, setAccessToken] = useState('')
  const [loginFetchState, setLoginFetchState] = useState<ProfileState>('not-fetched')

  const tokenRequest = getRedirectRequest('/#/user-profile')
  const { acquireToken } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    // Silently acquires an access token which is then attached to a request to backend
    if (inProgress !== (InteractionStatus.Logout || InteractionStatus.Startup)) {
      acquireToken(InteractionType.Redirect, tokenRequest)
        .then(authResult => {
          const token = authResult ? authResult.accessToken : ''
          setAccessToken(token)
          api.getProfile(undefined, { accessToken: token }).then(response => {
            if (response.success) {
              setGraphData(response.data)
              setLoginFetchState('fetched')
            } else {
              setLoginFetchState('failed')
            }
          })
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, []) // TODO: eslint - React Hook useEffect has missing dependencies: 'accounts', 'inProgress', and 'instance'
  // }, [instance, accounts, inProgress]) // TODO: api.getProfile is called three times

  useEffect(() => {
    if (loginFetchState === 'not-fetched') {
      return
    }

    /*if (graphData && graphData.organization) {
      return
    }*/

    api
      .getOrganizationApplicationStatus(undefined, { accessToken: accessToken })
      .then(response => {
        if (response.success) {
          setOrgMembershipStatus(response.data)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }, [loginFetchState])

  if (loginFetchState === 'not-fetched') {
    return <Loader text={t('common:loading')} />
  }

  if (loginFetchState === 'failed') {
    return <ErrorState error="generic" />
  }

  return (
    <PageContainer>
      <AuthenticatedTemplate>
        {graphData ? (
          <ProfileData
            graphData={graphData}
            setGraphData={setGraphData}
            accessToken={accessToken}
            orgMembershipStatus={orgMembershipStatus}
          />
        ) : (
          <></>
        )}
      </AuthenticatedTemplate>
    </PageContainer>
  )
}
