import { useField, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import { Comp } from '../../utils/component'
import { Checkbox } from './checkbox/checkbox'
import { DatePicker } from './date-picker/date-picker'
import Radio, { RadioBooleanValue } from './radio/radio'
import ReactSelect, { SelectFieldProps, SelectOption } from './select/react-select'
import TextInput from './text-input/text-input'
import { getFirstErrorPath } from './util'

type CommonProps = {
  label: string
  name: string
  disabled?: boolean
  optional?: boolean
  mandatory?: boolean
}

type SelectProps = CommonProps & SelectFieldProps

type RadioProps = { label?: string; name: string }
type RadioSelectProps<Value extends string> = RadioProps & { options: SelectOption<Value>[] }
type RadioGroupBooleanProps = RadioProps & { options: [RadioBooleanValue, RadioBooleanValue] }

type TextProps = CommonProps & { placeholder?: string; upperCase?: boolean; noWhitespace?: boolean }

export const CheckboxField: Comp<CommonProps> = props => {
  const { label, name } = props

  const [field, meta] = useField(name)
  const error = meta.touched ? meta.error : undefined
  return <Checkbox handleChange={field.onChange} label={label} name={name} checked={field.value ?? false} error={error} />
}

export const RadioGroupBooleanField: Comp<RadioGroupBooleanProps> = props => {
  const { label, name, options } = props

  const [field, meta, helpers] = useField<boolean | ''>(name)
  const error = meta.touched ? meta.error : undefined
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.value === 'true')
  }

  return (
    <Radio label={label} name={name} onChange={onChange} selected={field.value.toString()} options={options} error={error} />
  )
}

export function RadioSelectField<Value extends string>(props: RadioSelectProps<Value>): JSX.Element {
  const { label, name, options } = props

  const [field, meta, helpers] = useField<Value | ''>(name)
  const error = meta.touched ? meta.error : undefined
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.value as Value)
  }

  return <Radio label={label} name={name} onChange={onChange} selected={field.value} options={options} error={error} />
}

// Partially based on: https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
export const SelectField: Comp<SelectProps> = props => {
  const { components, disabled, label, name, noOptionsMessage, optional, options } = props

  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <ReactSelect
      components={components}
      errorMessage={meta.touched && meta.error ? t(meta.error) : undefined}
      id={name}
      label={label}
      name={name}
      noOptionsMessage={noOptionsMessage}
      onChange={value => setFieldValue(name, value)}
      options={options}
      selected={field.value}
      state={{ isClearable: optional, isDisabled: disabled }}
    />
  )
}

export const BirthDateField: Comp<{ name: string; label: string }> = props => {
  const { name } = props

  const { t } = useTranslation()
  const { setFieldTouched, setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const onChange = (value: string) => {
    setFieldTouched(name, true)
    setFieldValue(name, value)
  }

  return (
    <DatePicker
      onChange={onChange}
      value={field.value}
      errorMessage={meta.touched && meta.error ? t(meta.error) : undefined}
    />
  )
}

export const TextField: Comp<TextProps> = props => {
  const { disabled, label, name, optional, placeholder, upperCase, noWhitespace, mandatory } = props

  const { t } = useTranslation()

  const [field, meta] = useField(name)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (upperCase) {
      e.target.value = e.target.value?.toUpperCase()
    }
    if (noWhitespace) {
      e.target.value = e.target.value?.replace(/\s/g, '')
    }
    field.onChange(e)
  }

  return (
    <TextInput
      disabled={disabled}
      error={meta.touched ? meta.error : undefined}
      fullWidth
      id={name}
      label={mandatory ? `${label} *` : optional ? `${label} (${t('common:not_required')})` : label}
      name={name}
      onBlur={disabled ? nothing : field.onBlur}
      onChange={disabled ? nothing : onChange}
      placeholder={placeholder}
      value={field.value ?? ''}
    />
  )
}

const nothing = () => undefined

export const ScrollToFirstInvalidField: React.FC = () => {
  const formik = useFormikContext()
  React.useEffect(() => {
    if (formik.isSubmitting && formik.isValidating) {
      const firstErrorKey = getFirstErrorPath(formik.errors)
      if (firstErrorKey) {
        const inputElement = document.getElementsByName(firstErrorKey)[0]
        setTimeout(() => {
          if (inputElement) {
            inputElement.parentElement?.scrollIntoView({ behavior: 'smooth' })
            inputElement.focus()
          } else {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
          }
        }, 100)
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors])
  return null
}

const useBeforeUnload = (when: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return null
    }
    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [when])
}

export const PromptIfDirty: Comp = () => {
  const { t } = useTranslation()
  const formik = useFormikContext()
  useBeforeUnload(formik.dirty && formik.submitCount === 0)

  return <Prompt when={formik.dirty && formik.submitCount === 0} message={t('common:before_unload')} />
}
