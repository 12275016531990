import { useEffect, useState, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Page } from '../layout-user-mgmt'
import { useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { api } from '../../services/api'
import { Comp } from '../../utils/component'
import Loader from '../../ui-user-mgmt/loader/loader'
import { getRedirectRequest } from './MsalInstance'
import { Button, DeleteButton } from '../../ui-user-mgmt'
import { TokenClaims } from '@azure/msal-common'
import { ErrorState } from '../error/error-page'
import styled from 'styled-components'
import { Organization } from 'traficom-registry-shared/out/model/UserProfile'
import { actions, RegistrationContext } from '../../state/registration'

type WorkQueueItem = WorkQueueItemExisting | WorkQueueItemApply

type WorkQueueItemExisting = {
  queueId: string
  timestamp: number
  requestType: string
  data: {
    newOrg: Organization
    oldOrg: Organization
    user: {
      name: string
      mail: string
    }
  }
}

type WorkQueueItemApply = {
  queueId: string
  timestamp: number
  requestType: string
  data: {
    newOrg: Organization
    orgId: string
  }
}

type WorkQueueItemCreateAttemptedWithParameters = {
  queueId: string
  timestamp: number
  requestType: string
  data: {
    newOrg: Organization
    orgId: string
    user: {
      name: string
      mail: string
    }
  }
}

/* eslint-disable */

export const AdminWorkqueue: Comp = () => {
  const { t } = useTranslation()
  const [roles, setRoles] = useState<string[]>([])
  const [idTokenClaims, setIdTokenClaims] = useState<TokenClaims>()
  const [accessToken, setAccessToken] = useState<string>('')
  const [workQueue, setWorkQueue] = useState<WorkQueueItem[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)

  const tokenRequest = getRedirectRequest('/#/admin/workqueue')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    const fetchData = async () => {
      if (result) {
        const claims = result.idTokenClaims as TokenClaims
        setIdTokenClaims(claims)
        setRoles(claims.roles ?? [])
        setAccessToken(result.accessToken)
        const queueResult = await api.getAnsWorkQueue(undefined, { accessToken: result.accessToken })
        if (queueResult.success) {
          setWorkQueue(queueResult.data as WorkQueueItem[])
        } else {
          console.error(queueResult) // TODO
        }
        setLoadingComplete(true)
      }
    }

    fetchData().catch(console.error)
  }, [error, result])

  if (error) {
    return <ErrorState error="generic" />
  }

  return (
    <PageContainer>
      <FormAndTitleContainer>
        <Page.Content title={t('user_management:ans_admin')}>
          {!loadingComplete && <Loader text={t('common:loading')} />}
          {loadingComplete && roles.some(r => r === 'ans_admin') ? (
            <>
              <h1>{t('user_management:work_queue')}</h1>
              <WorkQueue items={workQueue} token={accessToken} />
            </>
          ) : (
            loadingComplete && <p>{t('user_management:no_access')}</p>
          )}
        </Page.Content>
      </FormAndTitleContainer>
    </PageContainer>
  )
}

const List = styled.ul`
  list-style: none;
  padding: 0px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 3px solid ${'#9b8dab'};
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
`

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border-top: 1px solid ${'#e0dddd'};
  :first-of-type {
    border-top: none;
  }
`

const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
`

const FormAndTitleContainer = styled.div`
  width: 50%;
  padding-bottom: 48px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Xicon = styled.span`
  padding 0px 14px;
  font-size: 18px;
  font-weight: 500;
`

const AstraGovOrgRequest: Comp<{ orgName: string; businessId: string }> = props => {
  const orgName = props.orgName
  const businessId = props.businessId
  return (
    <p>
      <Trans i18nKey="user_management:astra_gov_org_join_request">
        {{ orgName }}
        {{ businessId }}
      </Trans>
    </p>
  )
}

const ExistingOrgInfoEvent: Comp<{ email: string; oldOrgName: string; newOrgName: string; businessId: string }> = props => {
  const userEmail = props.email
  const oldOrgName = props.oldOrgName
  const newOrgName = props.newOrgName
  const businessId = props.businessId
  return (
    <p>
      <Trans i18nKey="user_management:org_exists_admin_info">
        {{ userEmail }}
        {{ oldOrgName }}
        {{ newOrgName }}
        {{ businessId }}
      </Trans>
    </p>
  )
}

const OrgHasParametersInfoEvent: Comp<{ email: string; newOrgName: string; businessId: string }> = props => {
  const userEmail = props.email
  const newOrgName = props.newOrgName
  const businessId = props.businessId
  return (
    <p>
      <Trans i18nKey="user_management:org_has_parameters_admin_info">
        {{ userEmail }}
        {{ newOrgName }}
        {{ businessId }}
      </Trans>
    </p>
  )
}

const WorkQueue: Comp<{ items: WorkQueueItem[]; token: string }> = props => {
  const { t } = useTranslation()
  const { dispatch } = useContext(RegistrationContext)

  const existingOrganizationEvents = props.items.filter(event => event.requestType === 'existingOrganization')
  const applyAstraGovOrgEvents = props.items.filter(event => event.requestType === 'applyForAstraGovOrg')
  const parametersExistOrgEvents = props.items.filter(
    event => event.requestType === 'organizationCreateAttemptedButParametersExist',
  )

  const approveAstraGovOrgApplication = async (item: WorkQueueItemApply) => {
    item.data.newOrg = { ...item.data.newOrg, astraGovernmentOrganization: true }
    const result = await api.updateOrganizationAsGovOrg(item, { accessToken: props.token })

    if (result.success) {
      window.location.reload()
    } else {
      console.error(result)
    }
  }

  const approveOrgCreation = async (item: WorkQueueItemCreateAttemptedWithParameters) => {
    // createOrganizationOnBehalf will also remove the relevant work item.

    const createResult = await api.createOrganizationOnBehalf(
      { ...item.data.newOrg, mail: item.data.user.mail },
      { accessToken: props.token },
    )

    if (createResult.success) {
      window.location.reload()
    } else {
      console.error(createResult)
      dispatch(actions.addSnackbarMessage(t(createResult.data.message!), 'ERROR'))
    }
  }

  return (
    <>
      {props.items.length > 0 && (
        <List>
          {parametersExistOrgEvents
            .map(item => item as WorkQueueItemCreateAttemptedWithParameters)
            .map(item => (
              <ListItem key={item.data.newOrg.businessId}>
                <OrgHasParametersInfoEvent
                  email={item.data.user.mail}
                  newOrgName={item.data.newOrg.name}
                  businessId={item.data.newOrg.businessId}
                />
                <ButtonGroup>
                  <DeleteButton
                    type="button"
                    variant="secondary"
                    $fullWidth
                    onClick={async () => {
                      const result = await api.deleteWorkQueueItem(item, { accessToken: props.token })
                      if (result.success) {
                        window.location.reload()
                      } else {
                        console.error(result) // TODO
                      }
                    }}
                  >
                    <Xicon>X</Xicon>
                    {t('user_management:remove_wq_item')}
                  </DeleteButton>
                  <Button variant="primary" size="small" onClick={() => approveOrgCreation(item)}>
                    {t('user_management:accept_astra_gov_org_join_request')}
                  </Button>
                </ButtonGroup>
              </ListItem>
            ))}
          {existingOrganizationEvents
            .map(item => item as WorkQueueItemExisting)
            .map(item => (
              <ListItem key={item.data.newOrg.businessId}>
                <ExistingOrgInfoEvent
                  email={item.data.user.mail}
                  oldOrgName={item.data.oldOrg.name}
                  newOrgName={item.data.newOrg.name}
                  businessId={item.data.newOrg.businessId}
                />
                <ButtonGroup>
                  <DeleteButton
                    type="button"
                    variant="secondary"
                    $fullWidth
                    onClick={async () => {
                      const result = await api.deleteWorkQueueItem(item, { accessToken: props.token })
                      if (result.success) {
                        window.location.reload()
                      } else {
                        console.error(result) // TODO
                      }
                    }}
                  >
                    <Xicon>X</Xicon>
                    {t('user_management:remove_wq_item')}
                  </DeleteButton>
                </ButtonGroup>
              </ListItem>
            ))}
          {applyAstraGovOrgEvents
            .map(item => item as WorkQueueItemApply)
            .map(item => (
              <ListItem key={item.data.newOrg.businessId}>
                <AstraGovOrgRequest orgName={item.data.newOrg.name} businessId={item.data.newOrg.businessId} />
                <ButtonGroup>
                  <DeleteButton
                    type="button"
                    variant="secondary"
                    $fullWidth
                    onClick={async () => {
                      const result = await api.deleteWorkQueueItem(item, { accessToken: props.token })
                      if (result.success) {
                        window.location.reload()
                      } else {
                        console.error(result) // TODO
                      }
                    }}
                  >
                    <Xicon>X</Xicon>
                    {t('user_management:reject_astra_gov_org_join_request')}
                  </DeleteButton>
                  <Button variant="primary" size="small" onClick={() => approveAstraGovOrgApplication(item)}>
                    {t('user_management:accept_astra_gov_org_join_request')}
                  </Button>
                </ButtonGroup>
              </ListItem>
            ))}
        </List>
      )}
    </>
  )
}
