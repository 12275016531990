import { unfold } from 'ramda'

type TransformProp<Values extends Obj, Key extends keyof Values = keyof Values, Value extends Values[Key] = Values[Key]> = (
  value: Value,
  key: Key,
) => unknown

type Obj = Record<string, unknown>

/**
 * Transforms the properties of an object, doesn't provide the correct return type.
 */
export function mapObject<Out extends { [K in keyof In]: unknown }, In extends Obj>(
  values: In,
  transform: TransformProp<In>,
): Out {
  const entries = Object.entries(values).map(([key, value]) => [key, transform(value as In[keyof In], key as keyof In)])

  return Object.fromEntries(entries)
}

export const rangeStep = (start: number, end: number, step: number): number[] =>
  unfold(n => (n <= end ? [n, n + step] : false), start)

export const dateToISODateString = (date: Date | null): string | null => (date ? date.toISOString().slice(0, 10) : null)

export const dateValuesToDate = (day?: number, month?: number, year?: number): Date | null => {
  if (day === undefined || month === undefined || year === undefined) {
    return null
  }
  const date = new Date()
  date.setUTCFullYear(year, month, day)
  return date.getUTCDate() === day && date.getUTCMonth() === month && date.getUTCFullYear() === year ? date : null
}
