import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Comp } from '../../../utils/component'
import Select, { ReactSelectState, SelectOption as BaseOption } from '../select/react-select'
import i18n from 'i18next'
import { dateToISODateString, dateValuesToDate, rangeStep } from '../../../utils'
import { Row, Col } from '../../layout/layout'

type SelectOption = BaseOption<number>

const StyledRow = styled(Row)`
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};
`

const StyledCol = styled(Col)`
  & > div {
    margin: 0;
  }
`
const state: ReactSelectState = {
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  isRtl: false,
  isSearchable: true,
}

export type DateValue = {
  day?: number
  month?: number
  year?: number
}

export type DatePickerProps = {
  value: string // yyyy-mm-dd
  onChange: (value: string) => void
  errorMessage?: string
}

const StyledHelperContainer = styled.div`
  display: block;
  margin-top: ${props => props.theme.spacing(0.5)};
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  font-size: 1rem;
  line-height: 1rem;
  min-height: 1rem;
`

const StyledError = styled.span`
  color: ${props => props.theme.palette.error};
`

const currentYear = new Date().getFullYear()
/** Determine name of month. Year doesn't matter, except that timezone changes may have weird browser bugs. */
const numberToLocaleMonth = (val: number) => new Date(2020, val).toLocaleString(i18n.language || 'fi', { month: 'long' })
const valueToOption = (value: number): SelectOption => ({ label: value + '', value })
const monthsToOption = (value: number): SelectOption => ({ label: numberToLocaleMonth(value), value })
const daysInMonth = (year: number, month: number) => new Date(year, month + 1, 0).getDate()

export const DatePicker: Comp<DatePickerProps> = ({ value, onChange, errorMessage }) => {
  const yearOptions = rangeStep(currentYear - 120, currentYear, 1)
    .reverse()
    .map(valueToOption)
  const monthOptions = rangeStep(0, 11, 1).map(monthsToOption)
  const initialDate = new Date(value)
  const validDate = value && !Number.isNaN(initialDate.getTime()) ? initialDate : null

  const initialDateValue: DateValue = {
    day: validDate?.getDate(),
    month: validDate?.getMonth(),
    year: validDate?.getFullYear(),
  }
  const [dateValue, setDateValue] = useState<DateValue>(initialDateValue)

  const dayOptionsByDaysInMonth = () => {
    const length = dateValue?.year && dateValue?.month ? daysInMonth(dateValue.year, dateValue.month) : 31
    return rangeStep(1, length, 1).map(valueToOption)
  }

  const callOnChange = (_dateValue: DateValue) => {
    if (_dateValue?.day !== undefined && _dateValue.month !== undefined && _dateValue.year !== undefined) {
      const { day, month, year } = _dateValue
      const date = dateValuesToDate(day, month, year)
      const dateString = dateToISODateString(date)
      onChange(dateString ?? '') // Clear value if invalid selection
    }
  }

  const onChangeValue = (key: keyof DateValue) => (value: number) => {
    const newDateValue = {
      ...dateValue,
      [key]: value,
    }
    // Set day value to max of current month/year selection
    if (newDateValue?.day !== undefined && newDateValue.month !== undefined && newDateValue.year !== undefined) {
      const { day, month, year } = newDateValue
      const daysInSelectedMonth = daysInMonth(year, month)
      newDateValue.day = day > daysInSelectedMonth ? daysInSelectedMonth : day
    }
    setDateValue(newDateValue)
    callOnChange(newDateValue)
  }

  const { t } = useTranslation('common')

  return (
    <>
      <StyledRow>
        <StyledCol sm={4}>
          <Select
            name="year"
            label={t('year')}
            options={yearOptions}
            selected={dateValue?.year}
            onChange={onChangeValue('year')}
            state={state}
            error={!!errorMessage}
          />
        </StyledCol>
        <StyledCol sm={4}>
          <Select
            name="month"
            label={t('month')}
            options={monthOptions}
            selected={dateValue?.month}
            onChange={onChangeValue('month')}
            state={state}
            error={!!errorMessage}
          />
        </StyledCol>
        <StyledCol sm={4}>
          <Select
            name="day"
            label={t('day')}
            options={dayOptionsByDaysInMonth()}
            selected={dateValue?.day}
            onChange={onChangeValue('day')}
            state={state}
            error={!!errorMessage}
          />
        </StyledCol>
      </StyledRow>
      {errorMessage && (
        <StyledHelperContainer>
          <StyledError>{errorMessage}</StyledError>
        </StyledHelperContainer>
      )}
    </>
  )
}
